import React from 'react';
import Body from '../../components/body/Body';
import Card from '../../components/cards/Card';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import DirectionsBoatFilledRoundedIcon from '@mui/icons-material/DirectionsBoatFilledRounded';
import AnchorRoundedIcon from '@mui/icons-material/AnchorRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes-constants';

const GhatOperator: React.FC = () => {
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(ROUTES.GHAT_OPERATOR.TRIP_MANAGE);
        toast.success("Clicked");
    }

    return (
        <Body>
            <h2 className='mb-1'>Today&apos;s Trips</h2>
            <h6 className='text-sky-600'>Upcoming</h6>
            <Card>
                <div className='flex'>
                    <div className='w-1/4 px-4 '>
                        <div className='text-sm mb-1'>Route</div>
                        <div className='flex items-center gap-4'>
                            <div>
                                <ArrowUpwardRoundedIcon className='text-neutral-500' />
                            </div>
                            <div>
                                <div className='font-medium mb-1'>Madhyamkanda</div>
                                <div className='font-medium mb-1'>Guwahati</div>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/4 px-4 '>
                        <div className='text-sm mb-1'>Vessel</div>
                        <div className='flex items-center'>
                            <div>
                                <div className='font-medium mb-1'><DirectionsBoatFilledRoundedIcon className='mr-3 text-neutral-500' fontSize='small' /> MV Charaidew</div>
                                <div className='font-medium mb-1'><AnchorRoundedIcon className='mr-3 text-neutral-500' fontSize='small' /> Dock: 3</div>
                            </div>
                        </div>
                    </div>
                    <div className='w-1/4 px-4 '>
                        <div className='text-sm mb-1'>Timings</div>
                        <div className='flex items-center'>
                            <div>
                                <div className='font-medium mb-1'><AccessTimeFilledRoundedIcon className='mr-3 text-neutral-500' fontSize='small' /> 09:30 AM</div>
                                <div className='font-medium mb-1'><HourglassTopRoundedIcon className='mr-3 text-neutral-500' fontSize='small' /> 01:00 hours</div>
                            </div>
                        </div>
                    </div>
                    <div className=' px-4 flex h-100 items-center w-1/4 justify-center'>
                        <PrimaryButton
                            onClick={handleClick}
                            buttonText="Tickets"
                        />
                    </div>
                </div>
            </Card>
        </Body>
    )
}

export default GhatOperator