import React, { useEffect } from 'react';
import './default-loader.sass'

const DefaultLoader: React.FC = () => {
    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <div className='loader-container'>
            <div className="loadingio-spinner-ripple-2by998twmg8">
                <div className="ldio-yzaezf3dcmj">
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};

export default DefaultLoader;
