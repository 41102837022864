import React, { forwardRef } from 'react'
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

interface ModalProps {
    title: string
    children: any
}

const Modal: React.ForwardRefRenderFunction<HTMLDialogElement, ModalProps> = ({ title, children }, ref) => {
    return (
        <dialog ref={ref} className={`rounded-xl py-4 px-8 scale-in-center w-full md:w-6/12 lg:w-4/12`}>
            <div className="flex justify-between w-full items-center">
                <h6 className='my-0'>{title}</h6>
                <form method="dialog">
                    <button className='bg-transparent p-1'><ClearRoundedIcon /></button>
                </form>
            </div>
            <div className='py-2'>
                {children}
            </div>
        </dialog>
    );
}

export default forwardRef(Modal)