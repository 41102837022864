import Popover from '@mui/material/Popover';
import React, { useState } from 'react';

const PopoverDetails: React.FC<{ children: any, buttonText: string }> = ({ children, buttonText }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <>
            <button className='underline underline-offset-1 text-sky-900' onClick={handleClick}>
                {buttonText}
            </button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                disableScrollLock
            >
                <div className="p-4">
                    {children}
                </div>
            </Popover>
        </>
    );
};

export default PopoverDetails;

