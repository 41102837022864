import { Route } from "../../manage/route-management/route-interfaces"
import { TripDeckItem } from "../../manage/trip-management/trip-interfaces"
import { VesselView } from "../../manage/vessel-management/vessel-interfaces"

export interface IRouteDetail {
    id: string,
    ghatFrom: { id: string, ghatName: string }
    ghatTo: { id: string, ghatName: string }
}

export const InitialRouteState = {
    id: '',
    ghatFrom: { id: '', ghatName: '' },
    ghatTo: { id: '', ghatName: '' }
}

export interface ISearchResult {
    id: string
    route: Route
    vessel: VesselView
    serviceType: string
    onlineSeat: string
    offlineSeat: string
    onlineSeatAvailable: string
    tripDeckItems: TripDeckItem[]
    tripDate: string
    departureTime: string
    tripDuration: string
    activateBefore: string
    dockNo: string
    bookingMode: string
    tripStatus: string
    quantity: number
}