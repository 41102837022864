import { ArrowForwardRounded } from '@mui/icons-material';
import React from 'react'
import { BookingResponse } from '../order-details-interfaces';
import { dateUtil } from '../../../../utility/dateUtil';

interface TripDetailsProps {
    bookingResponse: BookingResponse
}

const TripDetails: React.FC<TripDetailsProps> = ({ bookingResponse }) => {
    return (
        <>
            <h4 className='mb-4 flex gap-x-4 items-center'>Trip:
                <span>{bookingResponse.booking.trip.route.ghatFrom.ghatName}</span>
                <span><ArrowForwardRounded /></span>
                <span>{bookingResponse.booking.trip.route.ghatTo.ghatName}</span>
            </h4>
            <section className="grid gap-4 sm:grid-cols-2 mb-6 border-b pb-6 ">
                <div>
                    <h3 className="font-medium text-lg text-gray-600">Vessel Information</h3>
                    <table>
                        <tbody>
                            <tr>
                                <td className='w-32'>Name</td>
                                <td className='font-medium'>{bookingResponse.booking.trip.vessel.name}</td>
                            </tr>
                            <tr>
                                <td className='w-32'>Reg No</td>
                                <td className='font-medium'>{bookingResponse.booking.trip.vessel.regNo}</td>
                            </tr>
                            <tr>
                                <td className='w-32'>Dock No</td>
                                <td className='font-medium'>{bookingResponse.booking.trip.dockNo}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <h3 className="font-medium text-lg text-gray-600">Trip Timing</h3>
                    <table>
                        <tbody>
                            <tr>
                                <td className='w-40'>Date</td>
                                <td className='font-medium'>{dateUtil.formatDateTime(bookingResponse.booking.trip.tripDate).split('|')[0].trim()}</td>
                            </tr>
                            <tr>
                                <td className='w-40'>Departure Time</td>
                                <td className='font-medium'>{bookingResponse.booking.trip.departureTime}</td>
                            </tr>
                            <tr>
                                <td className='w-40'>Duration</td>
                                <td className='font-medium'>{bookingResponse.booking.trip.tripDuration}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    )
}

export default TripDetails;