import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

interface MenuWrapperProps {
    id: string;
    title: string | React.ReactNode;
    items: any[];
}

const MenuWrapper: React.FC<MenuWrapperProps> = ({ id, title, items }) => {
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemClick = (href: string) => {
        navigate(href);
        handleClose();
    }

    return (
        <div>
            <button
                id="basic-button"
                onClick={handleClick}
                className='font-medium text-blue-500 hover:text-blue-600'
            >
                {title}
            </button>
            <Menu
                id={id}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                MenuListProps={{
                    'aria-labelledby': id,
                }}
                disableScrollLock={true}
            >
                {
                    items.map((item: any, index: number) => (
                        <MenuItem
                            key={index}
                            onClick={() => handleItemClick(item.href)}
                        >
                            <item.icon fontSize='small' className='mr-4 text-blue-500' />
                            <span className='text-blue-500 pr-4 font-medium'>{item.name}</span>
                        </MenuItem>
                    ))
                }
            </Menu>
        </div>
    );
}

export default MenuWrapper;