import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DefaultLoader from '../../components/loader/DefaultLoader';
import { ROUTES } from '../../constants/routes-constants';

const Loading: React.FC = () => {
    const navigate = useNavigate()
    const location = useLocation();

    // const from = location.state?.from?.pathname || ROUTES.INDEX;
    const from = ROUTES.INDEX;

    /**
     * Call this function to redirect the user to the homepage.
     */
    const redirectUser = () => {
        navigate(from, { replace: true });
    }
    return (
        <div className="flex flex-col min-h-screen justify-between bg-slate-50">
            <div className="main-content">
                <div className="container mt-3 max-w-7xl mx-auto px-4 sm:px-6">
                    <DefaultLoader />
                </div>
            </div>
        </div>

    )
}

export default Loading
