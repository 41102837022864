import React from 'react'
import { BookingTicketTypes, PaymentPost } from './order-details-interfaces'
import { useHandleErrorService } from '../../../hooks/useHandleErrorService';
import { AxiosResponse } from 'axios';
import { useApiService } from '../../../hooks/useApiService';
import API_ENDPOINT from '../../../constants/api-constants';

export default function useOrderDetailsService() {

    const { handleError } = useHandleErrorService();
    const { postAuthRequest } = useApiService();

    const getTableData = (type: BookingTicketTypes) => {
        const headersMap = {
            passengers: [
                { name: 'passengerName', label: 'Name' },
                { name: 'gender', label: 'Gender' },
                { name: 'age', label: 'Age' },
                { name: 'pwd', label: 'Is Pwd?' },
                { name: 'ticketNo', label: 'Ticket No' }
            ],
            vehicles: [
                { name: 'regNo', label: 'Reg No' },
                { name: 'model', label: 'Model' },
                { name: 'ownerName', label: 'Owner Name' },
                { name: 'ticketNo', label: 'Ticket No' }
            ],
            goods: [
                { name: 'description', label: 'Description' },
                { name: 'weight', label: 'Weight' },
                { name: 'ticketNo', label: 'Ticket No' }
            ]
        };

        const titlesMap = {
            passengers: 'Passenger Details',
            vehicles: 'Vehicle Details',
            goods: 'Goods Details'
        };

        return {
            title: titlesMap[type],
            headers: headersMap[type]
        }
    }

    const updatePayment = async (bookingPayment: PaymentPost) => {
        try {
            const response: AxiosResponse = await postAuthRequest(
                API_ENDPOINT.BOOKING.UPDATE_PAYMMENT,
                bookingPayment
            )

            return true;
            
        } catch (error) {
            handleError(error);
        }
    }

    return {
        getTableData,
        updatePayment
    }
}
