export const ROLES = {
    ANY_ROLE: [
        'SYSADMIN',
        'DIVISION_HEAD',
        'GHAT_IC',
        'OPERATOR',
        'USER',
    ],
    SYSADMIN: 'SYSADMIN',
    DIVISION_HEAD: 'DIVISION_HEAD',
    GHAT_IC: 'GHAT_IC',
    OPERATOR: 'OPERATOR',
    USER: 'USER',
}