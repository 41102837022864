import React from 'react';

interface DefaultButtonProps {
    onClick: () => void;
    buttonText: string;
    buttonIcon?: React.ElementType;
    isDisabled?: boolean
}

const DefaultButton: React.FC<DefaultButtonProps> = ({ onClick, buttonText, buttonIcon: ButtonIcon, isDisabled }) => {
    return (
        <button type="submit" onClick={onClick}
            className="w-full text-white bg-sky-600 hover:bg-sky-700 focus:ring-4 
                    transition duration-300 focus:outline-none focus:ring-sky-300 font-medium rounded-lg 
                    disabled:bg-slate-400
                    px-5 text-center"
            style={{
                paddingTop: '0.575rem',
                paddingBottom: '0.575rem'
            }}
            disabled={isDisabled}
        >
            {buttonText}
            {ButtonIcon && <ButtonIcon className='ml-2 -mt-1' />}
        </button>
    );
};

export default DefaultButton;