import { DataGrid, GridColDef } from '@mui/x-data-grid'
import React from 'react'
import { DivisionTableRows } from './division-interfaces'
import TableActionButton from '../../../components/buttons/TableActionButton'
import { BorderColor, DeleteForever } from '@mui/icons-material'

interface DivisionTableProps {
    rows: DivisionTableRows[]
    handleEditClick: (id: string, divisionName: string, status: string) => Promise<void>
    handleDeleteClick: (id: string, divisionName: string) => Promise<void>
}

const DivisionTable: React.FC<DivisionTableProps> = ({ rows, handleEditClick, handleDeleteClick }) => {

    const cols: GridColDef[] = [
        { field: 'slNo', headerName: 'Sl No', flex: 4, minWidth: 50 },
        { field: 'divisionName', headerName: 'Division Name', type: 'string', flex: 4, minWidth: 150 },
        { field: 'status', headerName: 'Status', type: 'string', flex: 4, minWidth: 150 },
        {
            align: 'center',
            flex: 3,
            minWidth: 200,
            headerAlign: 'center',
            field: 'action',
            headerName: 'Action',
            sortable: false,
            filterable: false,
            renderCell: params => {
                const { id, divisionName, status } = params.row;
                return (
                    <div className='flex gap-2 justify-center items-center h-full'>
                        <TableActionButton
                            type='primary'
                            onClick={() => handleEditClick(id, divisionName, status)}>
                            <BorderColor fontSize='small' />
                        </TableActionButton>
                        <TableActionButton
                            type='danger'
                            onClick={() => handleDeleteClick(id, divisionName)}>
                            <DeleteForever fontSize='small' />
                        </TableActionButton>
                    </div>
                );
            },
        }
    ];

    return (
        <div className='mt-3 '>
            <div className='flex justify-center'>
                <div className='flex w-full bg-white'>
                    <DataGrid
                        rows={rows}
                        columns={cols}
                        autosizeOnMount
                        autoHeight
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[5, 10, 15]}
                    />
                </div>
            </div>
        </div>
    )
}

export default DivisionTable