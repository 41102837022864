import React from 'react';
import Body from '../../../components/body/Body';
import { Cog6ToothIcon } from '@heroicons/react/24/outline';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HttpsIcon from '@mui/icons-material/Https';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Card from '../../../components/cards/Card';
import NotificationsIcon from '@mui/icons-material/Notifications';
import HelpIcon from '@mui/icons-material/Help';
import Account from './Account';
import Password from './Password';
import Notification from './Notification';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const tabButtonStyleProps: object = {
    textTransform: 'none',
    display: 'flex',
}

const AccountSettings: React.FC = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Body>
            <h3 className='flex gap-4'><Cog6ToothIcon width={'1.75rem'} /> Account Settings</h3>
            <div>
                <Card>
                    <div className='h-full'>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons
                            allowScrollButtonsMobile
                            sx={{borderBottom: 1, borderColor: 'divider'}}
                        >
                            <Tab icon={<AccountCircleIcon />} label="Account" iconPosition="start" style={tabButtonStyleProps} {...a11yProps(0)} />
                            <Tab icon={<HttpsIcon />} label="Password" iconPosition="start" style={tabButtonStyleProps} {...a11yProps(1)} />
                            <Tab icon={<NotificationsIcon />} label="Notifications" iconPosition="start" style={tabButtonStyleProps} {...a11yProps(2)} />
                            <Tab icon={<HelpIcon />} label="Help" iconPosition="start" style={tabButtonStyleProps} {...a11yProps(3)} />
                        </Tabs>
                        <TabPanel value={value} index={0}>
                            <Account />
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            <Password />
                        </TabPanel>
                        <TabPanel value={value} index={2}>
                            <Notification />
                        </TabPanel>
                        <TabPanel value={value} index={3}>
                            <Password />
                        </TabPanel>
                    </div>
                </Card>
            </div>
        </Body>
    )
}

export default AccountSettings;