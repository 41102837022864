import React, { useEffect, useState } from 'react'
import { Goods, InitialGoodsState, ModalDetails } from '../ticket-booking-interfaces'
import { dateUtil } from '../../../../utility/dateUtil'
import toast from 'react-hot-toast'
import ModalWrapper from '../../../../components/modals/ModalWrapper'
import FormControl from '../../../../components/forms/FormControl'
import DefaultButton from '../../../../components/buttons/DefaultButton'

interface GoodsModalProps {
    goodsList: Goods[]
    setGoodsList: React.Dispatch<React.SetStateAction<Goods[]>>
    modalDetails: ModalDetails
    setModalDetails: React.Dispatch<React.SetStateAction<ModalDetails>>
    availability: number
}

const GoodsModal: React.FC<GoodsModalProps> = ({ ...props }) => {

    const [goodsForm, setGoodsForm] = useState<Goods>(InitialGoodsState);

    const [goodsModalOpen, setGoodsModalOpen] = useState(false);
    const handleModalOpen = () => {
        if (props.availability === 0) {
            toast.error("No slot available to book.");
            return;
        }
        setGoodsModalOpen(true);
    }
    const handleModalClose = () => {
        props.setModalDetails({
            action: '',
            itemType: '',
            id: ''
        });
        setGoodsModalOpen(false)
    };

    useEffect(() => {
        if (props.modalDetails.itemType === 'goods') {
            if (props.modalDetails.action === 'add') {
                const id = dateUtil.getTimestamp().toString();
                setGoodsForm({
                    id: id,
                    description: '',
                    weight: ''
                })
            }
            handleModalOpen();
        }
    }, [props.modalDetails])

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { value, name } = e.target;

        setGoodsForm((prevForm) => ({
            ...prevForm,
            [name]: value
        }));
    }

    const handleSaveClick = () => {
        const goodsIndex = props.goodsList.findIndex(goods => goods.id == goodsForm.id);
        if (goodsIndex != -1) {
            const updatedGoodsList = [...props.goodsList];
            updatedGoodsList[goodsIndex] = goodsForm;
            props.setGoodsList(updatedGoodsList)
            toast.success('Goods Details Updated');
        } else {
            props.setGoodsList([...props.goodsList, goodsForm]);
            toast.success('Goods Added');
        }
        handleModalClose();
    }

    return (
        <ModalWrapper
            open={goodsModalOpen}
            handleClose={handleModalClose}
            title={'Goods Details'} >
            <div id='goodsForm' className='flex flex-wrap w-full'>
                <div className='w-full mb-3 lg:w-1/4'>
                    <input type="text" value={goodsForm.id} readOnly hidden />
                    <FormControl
                        label='Weight(qls)'
                        type={'number'}
                        id={'weight'}
                        value={goodsForm.weight}
                        onChange={handleInputChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='w-full mb-3 lg:w-3/4 lg:pl-4'>
                    <FormControl
                        label='Description'
                        type={'text'}
                        id={'description'}
                        value={goodsForm.description}
                        onChange={handleInputChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='flex justify-center w-full mt-3'>
                    <DefaultButton
                        onClick={handleSaveClick}
                        buttonText={"Save Goods Details"}
                    />
                </div>
            </div>
        </ModalWrapper >
    )
}

export default GoodsModal;