import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DefaultButton from '../../../../components/buttons/DefaultButton';
import Card from '../../../../components/cards/Card';
import FormControl from '../../../../components/forms/FormControl';
import { ROUTES } from '../../../../constants/routes-constants';
import { useFormErrorContext } from '../../../../hooks/useFormErrorContext';
import { validationService } from '../../../../services/validationService';
import { Trip } from '../../../manage/trip-management/trip-interfaces';
import { useBookingSummaryService } from '../useBookingSummaryService';
import { useTicketBookingService } from '../useTicketBookingService';
import { IBookingSummary, Goods, Passenger, Vehicle, InitialBookingSummary, BookingDetailsPost } from '../ticket-booking-interfaces';
import useAuth from '../../../../hooks/useAuth';

interface BookingSummaryProps {
    trip: Trip
    passengerList: Passenger[]
    vehicleList: Vehicle[]
    goodsList: Goods[]
    bookingId: string
}

interface BookingUserForm {
    email: string
    mobileNo: string
}

interface FormErrors {
    mobileNo?: string;
    email?: string;
}

const BookingSummary: React.FC<BookingSummaryProps> = ({ ...props }) => {

    const navigate = useNavigate();
    const { saveBookingDetails } = useTicketBookingService();
    const { updatePriceDetails } = useBookingSummaryService()
    const { setFormErrors } = useFormErrorContext();

    const [bookingSummary, setBookingSummary] = useState<IBookingSummary>(InitialBookingSummary);

    const { auth } = useAuth();

    useEffect(() => {
    }, [props.passengerList, props.vehicleList, props.goodsList]);

    const [bookingUserForm, setBookingUserForm] = useState<BookingUserForm>({
        email: auth.user?.email || '',
        mobileNo: auth.user?.mobileNo || ''
    })

    const validateForm = (formData: BookingUserForm) => {
        const errors: FormErrors = {};
        !formData.mobileNo && (errors.mobileNo = "Mobile No cannot be blank");
        !formData.email && (errors.email = "Email cannot be blank");
        return Object.keys(errors).length > 0 ? errors : null;
    };


    useEffect(() => {
        if (props.passengerList.length === 0 && props.vehicleList.length === 0 && props.goodsList.length === 0) {
            setBookingSummary(InitialBookingSummary);
        } else {
            const summary = updatePriceDetails(props.trip.tripDeckItems, props.passengerList, props.vehicleList, props.goodsList);
            setBookingSummary(summary);
        }
    }, [props.trip, props.passengerList, props.vehicleList, props.goodsList]);


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === 'mobileNo' && !validationService.isValidMobileInput(value)) return;
        setBookingUserForm({
            ...bookingUserForm,
            [name]: value
        });
    }

    const handleProceedToPayClick = async () => {
        const errors = validateForm(bookingUserForm);
        if (errors) {
            setFormErrors(errors);
            return;
        }
        const bookingDetails: BookingDetailsPost = {
            booking: {
                id: Number(props.bookingId),
            },
            trip: {
                id: Number(props.trip.id)
            },
            email: bookingUserForm.email,
            mobileNo: bookingUserForm.mobileNo,
            passengers: props.passengerList,
            vehicles: props.vehicleList,
            goods: props.goodsList
        }
        const bookingResponse = await saveBookingDetails(bookingDetails);

        bookingResponse && navigate(ROUTES.USER.ORDER_DETAILS, { state: { bookingResponse: bookingResponse } });
    }

    return (
        <div className="w-full lg:w-4/12 lg:pl-2 h-full mt-4">
            <h4 className='mb-2'>Booking Summary</h4>
            <Card addClass={"h-full"}>
                <div className=''>
                    <table className="w-full border-collapse mb-4">
                        <thead>
                            <tr className='text-left'>
                                <th className="p-2">Ticket Type</th>
                                <th className="p-2 text-right">Price</th>
                                <th className="p-2 text-right">Qty</th>
                                <th className="p-2 text-right">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                bookingSummary.tickets.map((summary, index) => (
                                    <tr key={index}>
                                        <td className="p-2 break-words">{summary.itemDetails}</td>
                                        <td className="p-2 text-right">{summary.itemPrice}</td>
                                        <td className="p-2 text-right">{summary.itemQuantity}</td>
                                        <td className="p-2 text-right">{summary.itemTotalPrice}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <div className="border-t border-zinc-300 pt-4 px-2">
                        {/* <div className="flex justify-between mb-2">
                            <span>Net Amount</span>
                            <span>{bookingSummary.price.netAmount}</span>
                        </div>
                        <div className="flex justify-between mb-2">
                            <span>Convenience Fee</span>
                            <span>{bookingSummary.price.convenienceFee}</span>
                        </div> */}
                        <div className="flex justify-between font-bold">
                            <span>Total</span>
                            <span>{bookingSummary.price.totalPrice}</span>
                        </div>
                    </div>

                    <div className="border-t border-zinc-300 pt-4 px-2 mt-4">
                        <form id='bookerForm' className='w-full space-y-4'>
                            <div className='text-sm font-medium'>Contact Details for Ticket Delivery</div>
                            <FormControl
                                type={'text'}
                                id={'mobileNo'}
                                placeholder='Mobile No'
                                value={bookingUserForm.mobileNo}
                                onChange={handleInputChange}
                                mandatoryField={true}
                            />
                            <FormControl
                                type={'email'}
                                id={'email'}
                                placeholder='Email'
                                value={bookingUserForm.email}
                                onChange={handleInputChange}
                                mandatoryField={true}
                            />
                        </form>
                    </div>
                    <div className='mt-4 px-2'>
                        <DefaultButton
                            onClick={handleProceedToPayClick}
                            buttonText={"Continue"}
                        >
                        </DefaultButton>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default BookingSummary;