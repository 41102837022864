import React, { createContext, useContext, useState, ReactNode } from 'react';

interface FormErrorContextType {
    formErrors: { [key: string]: string };
    setFieldError: (fieldName: string, message: string) => void;
    setFormErrors: React.Dispatch<React.SetStateAction<any>>
    clearFormErrors: () => void;
}

const FormErrorContext = createContext<FormErrorContextType | undefined>(undefined);

export const FormErrorProvider = ({ children }: { children: ReactNode }) => {
    const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({});

    const setFieldError = (fieldName: string, message: string) => {
        setFormErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: message,
        }));
    };

    const clearFormErrors = () => {
        setFormErrors({});
    };

    return (
        <FormErrorContext.Provider value={{ formErrors, setFieldError, setFormErrors, clearFormErrors }}>
            {children}
        </FormErrorContext.Provider>
    );
};

export default FormErrorContext;