import { Route } from "../route-management/route-interfaces"
import { VesselView } from "../vessel-management/vessel-interfaces"

export interface TripManagementTableRow {
    slNo: number
    id: string
    route: string
    tripDate: string
    departureTime: string
    tripDuration: string
    dockNo: string
    bookingMode: string
    serviceType: string
    vessel: string
    onlineSeat: string
    offlineSeat: string
    status: string
    deckItems: TripDeckItem[]
}

export interface TripDeckItem {
    deck: {
        id: string
        deckNo: string
    }
    item: {
        id: string
        itemType: string
        unitType: string
        itemCategory: string
    }
    rate: string
    capacityValue: string
    availableValue: string
}


export interface TripPost {
    id: string | null
    route: {
        id: string
    }
    vessel: {
        id: string
    }
    serviceType: string
    onlineSeat: string
    offlineSeat: string
    tripDate: string
    dockNo: string
    departureTime: string
    tripDuration: string
    activateBefore: string
    bookingMode: string
    tripStatus: string
}

export interface Trip {
    id: string
    route: Route
    vessel: VesselView
    serviceType: string
    onlineSeat: string
    offlineSeat: string
    onlineSeatAvailable: string
    tripDeckItems: TripDeckItem[]
    tripDate: string
    departureTime: string
    tripDuration: string
    activateBefore: string
    dockNo: string
    bookingMode: string
    tripStatus: string
}

export const InitialTripState: Trip = {
    id: '',
    route: {
        id: '',
        ghatFrom: {
            id: '',
            division: {
                id: '',
                divisionName: ''
            },
            ghatName: '',
            status: ''
        },
        ghatTo: {
            id: '',
            division: {
                id: '',
                divisionName: ''
            },
            ghatName: '',
            status: ''
        },
        status: ''
    },
    vessel: {
        id: '',
        name: '',
        regNo: '',
        owner: '',
        vesselType: '',
        hasNightNavigation: '',
        luggageCapacity: '',
        tripDays: [],
        vesselDeckItems: []
    },
    serviceType: '',
    onlineSeat: '',
    offlineSeat: '',
    onlineSeatAvailable: '',
    tripDeckItems: [
        {
            deck: {
                id: '',
                deckNo: ''
            },
            item: {
                id: '',
                itemType: '',
                unitType: '',
                itemCategory: ''
            },
            rate: '',
            capacityValue: '',
            availableValue: ''
        }
    ],
    tripDate: '',
    departureTime: '',
    tripDuration: '',
    activateBefore: '',
    dockNo: '',
    bookingMode: '',
    tripStatus: ''
}