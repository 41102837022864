import { TripDeckItem } from "../../manage/trip-management/trip-interfaces";
import { IBookingSummary, Goods, Passenger, Ticket, Vehicle } from "./ticket-booking-interfaces";

export const useBookingSummaryService = () => {
    const updatePriceDetails = (
        deckItems: TripDeckItem[],
        passengerList: Passenger[],
        vehicleList: Vehicle[],
        goodsList: Goods[]
    ): IBookingSummary => {
        const ticketList: { Passenger: Ticket[]; Vehicle: Ticket[]; Goods: Ticket[] } = {
            Passenger: [],
            Vehicle: [],
            Goods: []
        };
        const conveniencePercentage = 3;
        let netAmount = 0;
        let convenienceFee = 0;

        // Helper function to add or update a ticket item
        const addOrUpdateTicket = (
            category: keyof typeof ticketList,
            deckItem: TripDeckItem | undefined,
            quantity = 1,
            totalMultiplier = 1
        ) => {
            if (!deckItem) return; // Exit if deckItem is undefined

            const itemPrice = Number(deckItem.rate);
            const itemType = deckItem.item.itemType;
            const existingIndex = ticketList[category].findIndex(item => item.itemType === itemType);
            const totalPrice = (itemPrice * totalMultiplier).toFixed(2);

            // Ticket details
            const newTicket: Ticket = {
                itemType,
                itemDetails: `${deckItem.item.itemCategory} (${itemType})`,
                itemQuantity: quantity,
                itemPrice: itemPrice.toFixed(2),
                itemTotalPrice: totalPrice,
            };

            if (category === 'Goods') {
                newTicket.itemDetails = `${deckItem.item.itemCategory} (${totalMultiplier} qls)`;
                newTicket.itemPrice = `${itemPrice.toFixed(2)}/ql`;
            }

            // Add to net amount and convenience fee
            netAmount += itemPrice * totalMultiplier;
            convenienceFee += (itemPrice * totalMultiplier * conveniencePercentage) / 100;

            if (existingIndex !== -1) {
                ticketList[category][existingIndex].itemQuantity += quantity;
                ticketList[category][existingIndex].itemTotalPrice = (
                    Number(ticketList[category][existingIndex].itemTotalPrice) + Number(totalPrice)
                ).toFixed(2);
            } else {
                ticketList[category].push(newTicket);
            }
        };

        // Mapping passenger categories to item types
        const passengerTypes: Record<string, string> = {
            PWD: 'PWD',
            Senior: 'Senior Citizen',
            Female: 'Female',
            Unreserved: 'Unreserved'
        };

        passengerList.forEach(passenger => {
            const type =
                passenger.pwd === 'Yes' ? passengerTypes.PWD :
                    passenger.age >= 60 ? passengerTypes.Senior :
                        passenger.gender === 'Female' ? passengerTypes.Female :
                            passengerTypes.Unreserved;

            const deckItem = deckItems.find(item => item.item.itemType === type);
            if (deckItem) {
                addOrUpdateTicket('Passenger', deckItem);
            } else {
                const fallbackDeckItem = deckItems.find(item => item.item.itemType === passengerTypes.Unreserved);
                addOrUpdateTicket('Passenger', fallbackDeckItem);
            }
        });

        vehicleList.forEach(vehicle => {
            const deckItem = deckItems.find(item => item.item.itemType === vehicle.vehicleType);
            addOrUpdateTicket('Vehicle', deckItem);
        });

        goodsList.forEach(goods => {
            const deckItem = deckItems.find(item => item.item.itemType === 'Goods');
            addOrUpdateTicket('Goods', deckItem, 1, parseFloat(goods.weight));
        });

        // const totalPrice = netAmount + convenienceFee;
        const totalPrice = netAmount;

        return {
            tickets: [...ticketList.Passenger, ...ticketList.Vehicle, ...ticketList.Goods],
            price: {
                netAmount: netAmount.toFixed(2),
                convenienceFee: convenienceFee.toFixed(2),
                totalPrice: totalPrice.toFixed(2)
            }
        };
    };

    return {
        updatePriceDetails
    };
};
