import React, { useEffect } from 'react'
import { IDeckItem } from '../../../../utility/deckItemUtil';
import { AddRounded } from '@mui/icons-material';

import BicycleIcon from '../../../../resources/icons/bicycle-icon.svg';
import BikeIcon from '../../../../resources/icons/bike-icon.svg';
import VehicleIcon from '../../../../resources/icons/vehicle-icon.svg';
import CarIcon from '../../../../resources/icons/car-icon.svg';
import LuggageIcon from '../../../../resources/icons/luggage-icon.svg';
import SeatIcon from '../../../../resources/icons/seat-icon.svg';
import { useTicketBookingService } from '../useTicketBookingService';
import { ModalDetails } from '../ticket-booking-interfaces';

interface AddTicketDetailsProps {
    items: IDeckItem
    setModalDetails: React.Dispatch<React.SetStateAction<ModalDetails>>
}

const AddTicketDetails: React.FC<AddTicketDetailsProps> = ({ items, setModalDetails }) => {

    const { addTicket } = useTicketBookingService()

    const ticketButtons = [
        { seatType: 'seat', icon: SeatIcon, availability: items.seatCapacity, hoverText: 'Add Passenger', unitLabel: 'Seats' },
        // { seatType: 'vehicle', icon: BicycleIcon, availability: items.bicycleCapacity, hoverText: 'Book Slot', unitLabel: 'Slots' },
        // { seatType: 'twoWheeler', icon: BikeIcon, availability: items.twoWheelerCapacity, hoverText: 'Book Slot', unitLabel: 'Slots' },
        { seatType: 'vehicle', icon: VehicleIcon, availability: items.fourWheelerCapacity + items.bicycleCapacity + items.twoWheelerCapacity, hoverText: 'Add Vehicle', unitLabel: 'Slots' },
        { seatType: 'goods', icon: LuggageIcon, availability: items.goodsCapacity, hoverText: 'Add Luggage', unitLabel: 'Qls' },
    ]

    const handleAddClick = (itemType: string) => {
        addTicket(itemType, setModalDetails)
    }

    return (
        <>
            <div className='mb-1'>
                Book Tickets
            </div>

            <div className="flex mb-3 flex-wrap">
                {
                    ticketButtons.map((button, index) => (
                        <div className="w-1/2 lg:w-1/3" key={index}>
                            <button className='w-full'
                                onClick={() => handleAddClick(button.seatType)}
                            >
                                <div className='px-2 mb-4 cursor-pointer relative'>
                                    <div className="bg-red-50 text-center px-4 py-4 rounded-lg outline outline-2 outline-red-100">
                                        <div className='h-10 flex mx-auto'>
                                            <img src={button.icon} className='w-full' alt="" />
                                        </div>
                                        <div className="flex flex-col items-center justify-center mb-2">
                                            <div className='font-medium text-sm text-neutral-600'>{button.hoverText}</div>
                                        </div>
                                    </div>
                                    <div className='absolute px-2 top-0 left-0 w-full h-full opacity-0 hover:opacity-100 duration-300 hover:backdrop:blur-md'>
                                        <div className='w-full h-full rounded-lg bg-red-50 bg-opacity-100'>
                                            <div className='flex flex-col justify-center items-center h-full text-red-400'>
                                                <AddRounded fontSize='large' />
                                                <div className="flex flex-col items-center justify-center">
                                                    <div className='text-sm'>Available</div>
                                                    <div className='font-semibold'>{button.availability}</div>
                                                    {/* <div className='text-xs'>{button.unitLabel}</div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </button>
                        </div>
                    ))
                }
            </div>

        </>
    )
}

export default AddTicketDetails;