import React, { ReactNode } from "react";

interface TableProps {
    bordered: boolean;
    theadRows?: string[];
    tbodyRows: (string | number | ReactNode)[][];
}

const Table: React.FC<TableProps> = ({ bordered, theadRows, tbodyRows }) => {
    return (
        <table className="table-auto w-full bg-white">
            {
                theadRows &&
                <thead>
                    <tr className="bg-slate-50">
                        {theadRows.map((row, index) => (
                            <th className={"text-left font-medium px-2 py-2 " + (bordered ? "border" : "")}
                                key={index}>{row}
                            </th>
                        ))}
                    </tr>
                </thead>
            }
            <tbody>
                {tbodyRows.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                            <td className={"text-left px-2 py-2 " + (bordered ? "border" : "")}
                                key={cellIndex}>{cell}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table >
    );
}

export default Table;