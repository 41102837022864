import { AxiosResponse } from "axios";
import toast from "react-hot-toast";
import API_ENDPOINT from "../../../constants/api-constants";
import { useApiService } from "../../../hooks/useApiService";
import { deckItemUtil, IDeckItem } from "../../../utility/deckItemUtil";
import { IRouteDetail, ISearchResult } from "./search-interfaces";
import { useHandleErrorService } from "../../../hooks/useHandleErrorService";

interface SearchResultServiceProps {
    route: IRouteDetail
    searchResults: ISearchResult[]
    setSearchResults: React.Dispatch<React.SetStateAction<ISearchResult[]>>
    setItemList: React.Dispatch<React.SetStateAction<IDeckItem[]>>
}

export const useSearchResultService = (props: SearchResultServiceProps) => {

    const { postAuthRequest } = useApiService();
    const { handleError } = useHandleErrorService();

    const processDeckItems = () => {
        const itemList: IDeckItem[] = deckItemUtil.processDeckItems(props.searchResults);
        props.setItemList(itemList);
    }

    const handleInputChange = (id: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (Number(value) > 10) {
            toast.error("Maximum allowed quantity is 10");
            return;
        }

        const updated = props.searchResults.map(trip => {
            if (trip.id == id) {
                trip.quantity = Number(value) || 0;
            }
            return trip;
        })

        props.setSearchResults(updated);

    }

    const initiateBooking = async (tripId: string, quantity: string) => {
        try {
            const response: AxiosResponse = await postAuthRequest(API_ENDPOINT.BOOKING.INITIATE_BOOKING,
                {
                    trip: {
                        id: tripId
                    },
                    passengerCount: quantity
                }
            );
            const { object } = response.data;
            return object;
        } catch (error) {
            handleError(error);
        }
    }

    return {
        initiateBooking,
        processDeckItems,
        handleInputChange
    }
};