import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { BookingResponse, BookingTicketTypes, IndividualTicket } from './order-details-interfaces';
import Body from '../../../components/body/Body';
import Card from '../../../components/cards/Card';
import { ArrowForwardRounded, Route } from '@mui/icons-material';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import TripDetails from './sections/TripDetails';
import TicketItemDetailsTable from './sections/TicketItemDetailsTable';
import useOrderDetailsService from './useOrderDetailsService';
import BookingInvoice from './sections/BookingInvoice';
import useBookingHistoryService from '../booking-history/ticket-list/useBookingHistoryService';
import Swal from 'sweetalert2';
import swalConfig from '../../../config/swalConfig';
import { ROUTES } from '../../../constants/routes-constants';
import { text } from 'stream/consumers';

const OrderDetails: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const bookingResponse: BookingResponse = location.state.bookingResponse;

    const { getTableData, updatePayment } = useOrderDetailsService();
    const { fetchTicketDetails } = useBookingHistoryService();

    const [ticketList, setTicketList] = useState<IndividualTicket[]>([]);

    useEffect(() => {
        const collectTickets = () => {
            const tickets: IndividualTicket[] = [];
            const types: BookingTicketTypes[] = ['passengers', 'vehicles', 'goods'];
            types.forEach((type) => {
                bookingResponse.booking[type]?.forEach(item => {
                    tickets.push({
                        ticketNo: item.ticketNo,
                        price: item.rate,
                    });
                });
            });
            setTicketList(tickets);
        };
        collectTickets();
        
    }, [bookingResponse]);

    const renderTable = (type: BookingTicketTypes) => {
        const tickets = bookingResponse.booking[type];
        if (!tickets || tickets.length === 0) return null;
        const { title, headers } = getTableData(type);
        return (
            <TicketItemDetailsTable
                title={title}
                headers={headers}
                tickets={tickets}
            />
        )
    }

    const handleProceedToPayment = async () => {
        const updated = await updatePayment({
            booking: {
                id: bookingResponse.booking.id
            },
            orderId: bookingResponse.orderId,
            paymentStatus: 'Paid',
            paymentMode: 'Online',
            pgResponse: 'dumyTextForPgResponse'
        });

        if (updated) {
            Swal.fire(swalConfig({
                title: 'Ticket Booking Successful!',
                text: 'Review booking details and download tickets in the next step.',
                icon: 'success',
                timer: 1500,
                showConfirmButton: false,
                position: 'center'
            })).then(async () => {
                const confiremedTicketDetails = await fetchTicketDetails(bookingResponse.booking.id);
                navigate(ROUTES.USER.BOOKING_TICKET_DETAILS, { state: { bookingTicketDetails: confiremedTicketDetails } });
            });
        }
    };

    return (
        <Body>
            <h1>Booking Details</h1>

            <div className='flex flex-wrap'>
                <div className='w-2/3 pr-4'>
                    <Card>
                        <div>
                            <TripDetails bookingResponse={bookingResponse} />

                            {renderTable('passengers')}
                            {renderTable('vehicles')}
                            {renderTable('goods')}
                        </div>

                        <div className='text-neutral-500 border rounded border-sky-300 px-4 py-2'>
                            <h6>Review Your Booking Details</h6>
                            <ul className='text-sm list-disc list-inside'>
                                <li className='mb-1'>Please review your booking information carefully before proceeding to payment. Ensure that all passenger, vehicle, and goods details are accurate.</li>
                                <li className='mb-1'>When you&apos;re ready, click Proceed to Payment to complete your booking.</li>
                                <li className='mb-1'>Need assistance? Contact our support team for help before finalizing your payment.</li>
                            </ul>
                        </div>
                    </Card>
                </div>

                <div className='w-1/3'>
                    <Card>
                        <BookingInvoice
                            ticketList={ticketList}
                            grossAmount={bookingResponse.grossAmount}
                            convenienceFee={bookingResponse.convenienceFee}
                            netAmount={bookingResponse.netAmount}
                            submitButton={
                                <PrimaryButton
                                    fullWidth={true}
                                    onClick={handleProceedToPayment}
                                    buttonText={'Proceed to Payment'}
                                />}
                        />
                    </Card>
                </div>
            </div>
        </Body>
    );
}

export default OrderDetails;
