import { TextField } from '@mui/material'
import React from 'react'

const Account: React.FC = () => {
    return (
        <div className='w-full'>
            <div className='flex w-full items-center'>
                <div className='w-1/2'>
                    Name
                </div>
                <div className='w-1/2'>
                    <TextField size='small' />
                </div>
            </div>
            <div className='flex w-full items-center'>
                <div className='w-1/2'>
                    Email
                </div>
                <div className='w-1/2'>
                    <TextField size='small' />
                </div>
            </div>
            <div className='flex w-full items-center'>
                <div className='w-1/2'>
                    Phone
                </div>
                <div className='w-1/2'>
                    <TextField size='small' />
                </div>
            </div>
        </div>
    )
}

export default Account