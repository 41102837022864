import { Trip } from "../../../manage/trip-management/trip-interfaces"
import { GoodsView, PassengerView, VehicleView } from "../../payment/order-details-interfaces"


export interface BookingPeriodFilter {
    isDefault: boolean
    fromDate: string | null
    toDate: string | null
}

export interface BookingDetails {
    id: number
    pnrNo: string
    bookingNo: string
    email: string
    mobileNo: string
    creationDate: string
    confirmationDate: string
    trip: Trip
    bookingMode: string
    bookingStatus: string
    passengers: PassengerView[]
    vehicles: VehicleView[]
    goods: GoodsView[]
    payment: {
        id: number
        orderId: string
        booking: string | null
        grossAmount: number
        convenienceFee: number
        netAmount: number
        paymentStatus: string
        paymentMode: string
    }
}

export const BookingStatus = {
    CONFIRM: 'Confirm',
    HOLD: 'Hold',
    PENDING: 'Pending',
    CHECKED_IN: 'CheckedIn',
}