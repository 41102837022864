import { Trip } from "../pages/manage/trip-management/trip-interfaces";
import { ISearchResult } from "../pages/unlogged/search-results/search-interfaces";

export interface IDeckItem {
    trip: string
    seatCapacity: number
    bicycleCapacity: number
    twoWheelerCapacity: number
    fourWheelerCapacity: number
    goodsCapacity: number
}

export const InitialDeckItemState = {
    trip: '',
    seatCapacity: 0,
    bicycleCapacity: 0,
    twoWheelerCapacity: 0,
    fourWheelerCapacity: 0,
    goodsCapacity: 0,
}

const processDeckItems = (searchResults: ISearchResult[]) => {
    const itemList: IDeckItem[] = [];
    searchResults.forEach(result => {
        let bicycleCapacity = 0;
        let twoWheelerCapacity = 0;
        let fourWheelerCapacity = 0;
        let goodsCapacity = 0;
        result.tripDeckItems.forEach(deckItem => {
            switch (deckItem.item.itemType) {
                case 'Bicycle':
                    bicycleCapacity = bicycleCapacity + Number(deckItem.availableValue);
                    break;
                case 'Two Wheeler':
                    twoWheelerCapacity = twoWheelerCapacity + Number(deckItem.availableValue);
                    break;
                case 'Four Wheeler':
                    fourWheelerCapacity = fourWheelerCapacity + Number(deckItem.availableValue);
                    break;
                case 'Goods':
                    goodsCapacity = goodsCapacity + Number(deckItem.availableValue);
                    break;
                default:
                    break;
            }
        })
        const items = {
            trip: result.id,
            seatCapacity: Number(result.onlineSeatAvailable),
            bicycleCapacity: bicycleCapacity,
            twoWheelerCapacity: twoWheelerCapacity,
            fourWheelerCapacity: fourWheelerCapacity,
            goodsCapacity: goodsCapacity,
        }

        itemList.push(items);
    });

    return itemList;
}

const processDeckItemsForTrip = (trip: Trip) => {

    let bicycleCapacity = 0;
    let twoWheelerCapacity = 0;
    let fourWheelerCapacity = 0;
    let goodsCapacity = 0;

    trip.tripDeckItems.forEach(deckItem => {
        switch (deckItem.item.itemType) {
            case 'Bicycle':
                bicycleCapacity = bicycleCapacity + Number(deckItem.availableValue);
                break;
            case 'Two Wheeler':
                twoWheelerCapacity = twoWheelerCapacity + Number(deckItem.availableValue);
                break;
            case 'Four Wheeler':
                fourWheelerCapacity = fourWheelerCapacity + Number(deckItem.availableValue);
                break;
            case 'Goods':
                goodsCapacity = goodsCapacity + Number(deckItem.availableValue);
                break;
            default:
                break;
        }
    });

    const items: IDeckItem = {
        trip: trip.id,
        seatCapacity: Number(trip.onlineSeatAvailable),
        bicycleCapacity: bicycleCapacity,
        twoWheelerCapacity: twoWheelerCapacity,
        fourWheelerCapacity: fourWheelerCapacity,
        goodsCapacity: goodsCapacity,
    }

    return items;
}

export const deckItemUtil = {
    processDeckItems,
    processDeckItemsForTrip
}