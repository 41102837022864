import React, { RefObject } from 'react'
import FormControl from '../../../components/forms/FormControl';
import DefaultButton from '../../../components/buttons/DefaultButton';

interface PassengerTabProps {
    passengerName: string;
    passengerAge: string;
    passengerGender: string;
    passengerIsPwd: string;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleAddButtonClick: () => void;
}

const PassengerTab: React.FC<PassengerTabProps> = ({ ...props }) => {
    return (
        <div>
            <form className='flex flex-wrap w-full '>
                <div className='w-full'>
                    <FormControl
                        type='text'
                        label='Name'
                        id='passengerName'
                        value={props.passengerName}
                        onChange={props.handleInputChange}
                    />
                </div>
                <div className='w-1/3 pr-4'>
                    <FormControl
                        type='number'
                        label='Age'
                        id='passengerAge'
                        value={props.passengerAge}
                        onChange={props.handleInputChange}
                    />
                </div>
                <div className='w-1/3 pr-4'>
                    <FormControl
                        type='select'
                        label='Gender'
                        id='passengerGender'
                        value={props.passengerGender}
                        options={[
                            { value: 'MALE', label: 'MALE' },
                            { value: 'FEMALE', label: 'FEMALE' },
                            { value: 'OTHERS', label: 'OTHERS' }
                        ]}
                        onChange={props.handleInputChange}
                    />
                </div>
                <div className='w-1/3'>
                    <FormControl
                        type='select'
                        label='Differently Abled?'
                        id='passengerIsPwd'
                        value={props.passengerIsPwd}
                        options={[
                            { value: 'NO', label: 'NO' },
                            { value: 'YES', label: 'YES' }
                        ]}
                        onChange={props.handleInputChange}
                    />
                </div>
                <div className='w-full mt-4'>
                    <DefaultButton
                        onClick={async () => props.handleAddButtonClick()}
                        buttonText={'Add Passenger'}
                    />
                </div>
            </form>
        </div>
    );
}

export default PassengerTab