const getTimestamp = () => new Date().getTime();

const getMonthName = (month: any) => {
    switch (month) {
        case 0: return "january";
        case 1: return "february";
        case 2: return "march";
        case 3: return "april";
        case 4: return "may";
        case 5: return "june";
        case 6: return "july";
        case 7: return "august";
        case 8: return "september";
        case 9: return "october";
        case 10: return "november";
        case 11: return "december";
        default: return "january";
    }
}

const getCurrentYear = () => new Date().getFullYear().toString();

const isCurrentYear = (year: string) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear().toString();

    if (year == currentYear) {
        return true;
    } else {
        return false;
    }
}

const isCurrentMonth = (month: string) => {
    const currentDate = new Date();
    const currentMonth = getMonthName(currentDate.getMonth());

    if (month.toLowerCase() == currentMonth) {
        return true;
    } else {
        return false;
    }
}

const formatDateTime = (inputDate: string) => {
    const date = new Date(inputDate);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const hours = date.getHours() % 12 || 12;
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const ampm = date.getHours() < 12 ? 'AM' : 'PM';

    const formattedDate = `${day}-${month}-${year} | ${hours}:${minutes} ${ampm}`;

    return formattedDate;
}

const formatDate = (inputDate: string) => {
    const date = new Date(inputDate);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
}

const formatDateWithDay = (inputDate: string) => {
    const date = new Date(inputDate);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    const formattedDate = `${day}-${month}-${year}`;

    const weekday = date.toLocaleDateString('en-US', { weekday: 'short' });

    return `${formattedDate}, ${weekday}`;
}

const formatTime = (timeString: string): string => {
    const [hours, minutes, seconds] = timeString.split(':');
    const hourNum = Number(hours);

    const ampm = hourNum >= 12 ? 'PM' : 'AM';
    const convertedHours = hourNum % 12 || 12;

    const formattedTime = `${convertedHours.toString().padStart(2, '0')}:${minutes.padStart(2, '0')} ${ampm}`;

    // if (seconds !== undefined) {
    //     formattedTime = `${convertedHours.toString().padStart(2, '0')}:${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')} ${ampm}`;
    // }

    return formattedTime;
}

const today = () => new Date().toISOString().split('T')[0];

function splitDate(dateString: string) {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
    };
    const formattedDate = date.toLocaleDateString('en-US', options);
    const parts = formattedDate.split(', ');
    const [monthDay, year] = parts;
    const [month, day] = monthDay.split(' ');

    return {
        day: day,
        month: month,
        year: year
    };
}


export const dateUtil = {
    getTimestamp,
    getCurrentYear,
    isCurrentYear,
    isCurrentMonth,
    formatDateTime,
    formatDate,
    formatTime,
    formatDateWithDay,
    today,
    splitDate
}