import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../constants/routes-constants'
import ErrorIllustration from '../../resources/images/404-illustration.svg';
import Body from '../../components/body/Body';
import PrimaryButton from '../../components/buttons/PrimaryButton';

const NotFound: React.FC = () => {
    const navigate = useNavigate()
    const location = useLocation();

    // const from = location.state?.from?.pathname || ROUTES.INDEX;
    const from = ROUTES.INDEX;

    /**
     * Call this function to redirect the user to the homepage.
     */
    const redirectUser = () => {
        navigate(from, { replace: true });
    }
    return (
        <Body>
            <div className='flex justify-center mt-8'>
                <img className='w-full md:w-1/2 lg:w-1/3' src={ErrorIllustration} alt="401" />
            </div>
            <div className='flex justify-center'>
                <PrimaryButton
                    onClick={redirectUser}
                    buttonText='Go Back'
                />
            </div>
            <div className='text-center text-sm mt-4 mx-auto w-full md:w-1/2 font-medium'>
                We can&apos;t seem to find the page you are looking for.
            </div>
        </Body>
    )
}

export default NotFound
