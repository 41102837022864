import React from 'react';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';

interface SearchInputProps {
    id: string;
    placeholder?: string;
    onChange: (e: React.ChangeEvent<any>) => void;
    value: any;
    inputRef?: React.RefObject<HTMLInputElement>;
}

const SearchInput: React.FC<SearchInputProps> = ({ id, placeholder, onChange, value, inputRef }) => {
    return (
        <div className='relative w-full border border-1 rounded-xl bg-slate-100'>
            <input
                id={id}
                type="text"
                className="pl-4 pr-10 py-2 w-full rounded-xl bg-transparent"
                onChange={onChange}
                value={value}
                placeholder={placeholder}
                autoFocus
                ref={inputRef}
            />
            <div className='absolute right-2 top-2'>
                <SearchRoundedIcon />
            </div>
        </div>
    )
}

export default SearchInput