import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RouteIcon from '@mui/icons-material/Route';
import React, { useEffect, useState } from 'react';
import Body from '../../../components/body/Body';
import DefaultButton from '../../../components/buttons/DefaultButton';
import Card from '../../../components/cards/Card';
import FormControl from '../../../components/forms/FormControl';
import ModalWrapper from '../../../components/modals/ModalWrapper';
import { SelectOption } from '../../../constants/type-constants';
import { useCommonService } from '../../../hooks/useCommonService';
import { RouteTableRows } from './route-interfaces';
import RouteTable from './RouteTable';
import { useRouteManagementService } from './useRouteManagementService';

const RouteManagement: React.FC = () => {
    const {
        fetchStatusOptions,
        fetchDivisionOptions,
        fetchGhatOptions,
        fetchGhatOptionsByDivision,
    } = useCommonService();

    const {
        handleInputChange,
        handleGhatInputChange,
        initializeOptions,
        fetchRoutes,
        saveRoute,
        deleteRoute,
    } = useRouteManagementService();

    const [divisionOptions, setDivisionOptions] = useState<SelectOption[]>([]);
    const [statusOptions, setStatusOptions] = useState<SelectOption[]>([]);
    const [ghatToOptions, setGhatToOptions] = useState<SelectOption[]>([]);
    const [ghatFromOptions, setGhatFromOptions] = useState<SelectOption[]>([]);
    const [ghatToList, setGhatToList] = useState<SelectOption[]>([]);
    const [ghatFromList, setGhatFromList] = useState<SelectOption[]>([]);

    const [division, setDivision] = useState('');
    const [route, setRoute] = useState('');
    const [ghatFrom, setGhatFrom] = useState('');
    const [ghatTo, setGhatTo] = useState('');
    const [status, setStatus] = useState('');

    const [routeTableRows, setRouteTableRows] = useState<RouteTableRows[]>([]);

    const [routeModalOpen, setRouteModalOpen] = useState(false);
    const handleRouteModalOpen = () => setRouteModalOpen(true);
    const handleRouteModalClose = () => setRouteModalOpen(false);

    useEffect(() => {
        fetchDivisionOptions(setDivisionOptions, setDivision);
        fetchStatusOptions(setStatusOptions, setStatus);
    }, []);

    useEffect(() => {
        if (division != '') {
            fetchGhatOptionsByDivision(division, setGhatFromList);
            fetchGhatOptions(setGhatToList);
        }
    }, [division]);

    const initialize = (ghatFromInitial?: string, ghatToInitial?: string) => {
        initializeOptions(
            ghatFromList,
            ghatToList,
            setGhatFrom,
            setGhatTo,
            setGhatFromOptions,
            setGhatToOptions,
            ghatFromInitial,
            ghatToInitial
        );
    }

    const handleFetchRoutes = () => fetchRoutes(division, setRouteTableRows);

    // ADD CLICK HANDLER
    const handleAddButtonClick = async () => {
        initialize();
        setStatus(statusOptions[0].value);
        handleRouteModalOpen();
    }

    // EDIT CLICK HANDLER
    const handleTableButtonEditClick = async (id: string, ghatFrom: string, ghatTo: string, status: string) => {
        initialize(ghatFrom, ghatTo);
        setStatus(status);
        handleRouteModalOpen();
    }

    // DELETE CLICK HANDLER
    const handleTableButtonDeleteClick = async (id: string, ghatFrom: string, ghatTo: string) => {
        const postData = {
            ghatFrom: { id: ghatFrom },
            ghatTo: { id: ghatTo },
            status: statusOptions.find(option => option.value == 'Deleted')?.value || 'Deleted'
        }
        const deleted = await deleteRoute(postData);
        if (deleted) {
            fetchRoutes(division, setRouteTableRows);
            handleRouteModalClose();
        }
    }

    // SAVE CLICK HANDLER
    const handleRouteSaveClick = async () => {
        const postData = {
            id: null,
            ghatFrom: { id: ghatFrom },
            ghatTo: { id: ghatTo },
            status: status
        }
        const saved = await saveRoute(postData);
        if (saved) {
            fetchRoutes(division, setRouteTableRows);
            handleRouteModalClose();
        }
    }

    return (
        <Body>
            <div className='flex flex-wrap justify-between items-center'>
                <h3 className='flex justify-center items-center gap-4'><RouteIcon /> Route Management</h3>
            </div>
            <Card>
                <div className='flex w-full items-end justify-between gap-2'>
                    <div className='flex items-end gap-2'>
                        <div>
                            <FormControl
                                label='Select Division'
                                type={'select'}
                                id={'division'}
                                value={division}
                                onChange={handleInputChange(setDivision)}
                                options={divisionOptions}
                            />
                        </div>
                        <div>
                            <DefaultButton
                                buttonText='Fetch Routes'
                                onClick={handleFetchRoutes}
                            />
                        </div>
                    </div>
                    <div className='flex w-max'>
                        <DefaultButton
                            onClick={handleAddButtonClick}
                            buttonText='Add New Route'
                            buttonIcon={AddRoundedIcon}
                        />
                    </div>
                </div>
            </Card>

            <RouteTable
                rows={routeTableRows}
                handleEditClick={handleTableButtonEditClick}
                handleDeleteClick={handleTableButtonDeleteClick}
            />

            <ModalWrapper
                open={routeModalOpen}
                handleClose={handleRouteModalClose}
                title={'Route Details'}>
                <form id='routeForm' className='flex flex-wrap'>
                    <div className='w-full lg:w-2/5 lg:pr-4'>
                        <input type='text' id='id' name='id' value={route} readOnly hidden />
                        <FormControl
                            label='From'
                            type={'select'}
                            id={'ghatFrom'}
                            value={ghatFrom}
                            options={ghatFromOptions}
                            onChange={handleGhatInputChange(setGhatFrom, ghatTo, ghatToList, setGhatToOptions)}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-2/5 lg:pr-4'>
                        <FormControl
                            label='To'
                            type={'select'}
                            id={'ghatTo'}
                            value={ghatTo}
                            options={ghatToOptions}
                            onChange={handleGhatInputChange(setGhatTo, ghatFrom, ghatFromList, setGhatFromOptions)}
                            mandatoryField={true}
                        />
                    </div>
                    <div className='w-full lg:w-1/5'>
                        <FormControl
                            label='Status'
                            type={'select'}
                            id={'status'}
                            value={status}
                            options={statusOptions}
                            onChange={handleInputChange(setStatus)}
                            mandatoryField={true}
                        />
                    </div>
                </form>
                <div className='mt-4'>
                    <DefaultButton
                        onClick={handleRouteSaveClick}
                        buttonText={'Save Route Details'}
                    />
                </div>
            </ModalWrapper>
        </Body>
    )
}

export default RouteManagement