import { AxiosResponse } from 'axios';
import useAuth from './useAuth';
import useAxiosInterceptor from './useAxiosInterceptor';

// Custom hook for API service
export const useApiService = () => {

    const { auth } = useAuth();
    const axiosInstance = useAxiosInterceptor();
    // GET request with authorization
    const fetchAuthRequest = async (
        urlEndPoint: string,
        params?: any
    ): Promise<AxiosResponse> => {
        return axiosInstance.get(urlEndPoint, {
            headers: {
                Authorization: `${auth.tokenType} ${auth.accessToken}`,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            params: params,
        });
    };

    // PUT request with authorization
    const putAuthRequest = async (
        urlEndPoint: string,
        data: any
    ): Promise<AxiosResponse> => {
        return axiosInstance.put(urlEndPoint, data, {
            headers: {
                Authorization: `${auth.tokenType} ${auth.accessToken}`,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
        });
    };

    // POST request with authorization
    const postAuthRequest = async (
        urlEndPoint: string,
        data: any
    ): Promise<AxiosResponse> => {
        return axiosInstance.post(urlEndPoint, data, {
            headers: {
                Authorization: `${auth.tokenType} ${auth.accessToken}`,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
        });
    };

    // DELETE request with authorization
    const deleteAuthRequest = async (
        urlEndPoint: string,
        data: any
    ): Promise<AxiosResponse> => {
        return axiosInstance.delete(urlEndPoint, {
            headers: {
                Authorization: `${auth.tokenType} ${auth.accessToken}`,
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            data: data,
        });
    };

    // POST request without authorization (public)
    const postPublicRequest = async (
        urlEndPoint: string,
        data: any,
        withCredentials?: boolean
    ): Promise<AxiosResponse> => {
        return axiosInstance.post(urlEndPoint, data, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            withCredentials: withCredentials
        });
    };

    // GET request without authorization (public)
    const fetchPublicRequest = async (
        urlEndPoint: string,
        data?: any
    ): Promise<AxiosResponse> => {
        return axiosInstance.get(urlEndPoint, {
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            params: data,
        });
    };

    return {
        fetchAuthRequest,
        putAuthRequest,
        postAuthRequest,
        deleteAuthRequest,
        postPublicRequest,
        fetchPublicRequest,
    };
};
