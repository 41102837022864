const getType = () => {
    return "WEB";
}

const getAddress = () => {
    return "0000-1111-2222-3333";
}

export const device = {
    getType,
    getAddress
}