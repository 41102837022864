
const isValidMobileInput = (value: string) => {
    if (value.length <= 10 && ((/^\d+$/).test(value) || value == '')) {
        return true;
    }
    return false;
}

const isValidMobileNumber = (phoneNumber: string) => {
    // Remove leading spaces and "+" sign (if present)
    phoneNumber = phoneNumber.trim().replace(/^\+/, "");

    // Check if the string consists only of digits and has a length of 10
    return /^\d{10}$/.test(phoneNumber);
}

export const validationService = {
    isValidMobileInput,
    isValidMobileNumber
}