import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ContentClickableProps {
    href: string;
    icon: React.ReactNode;
    header: string;
    text: string;
}

const ContentClickable: React.FC<ContentClickableProps> = ({ href, icon, header, text }) => {
    const navigate = useNavigate();

    return (
        <div className="group relative flex items-center gap-x-6 rounded-xl p-4 text-sm leading-6 bg-gray-50 bg-opacity-60 hover:bg-gray-50 hover:bg-opacity-100 duration-300">
            <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-sky-100">
                {icon && <div className="h-6 w-6 text-gray-600 group-hover:text-sky-600">{icon}</div>}
            </div>
            <div className="flex-auto">
                <button onClick={() => navigate(href)} className="block font-semibold text-gray-900">
                    {header}
                    <span className="absolute inset-0" />
                </button>
                <p className="mt-1 text-gray-600">
                    {text}
                </p>
            </div>
        </div>
    )
}

export default ContentClickable;