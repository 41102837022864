import React, { useEffect, useState } from 'react';
import { BookingDetails } from './booking-history-interfaces';
import useBookingHistoryService from './useBookingHistoryService';
import BookingDetailsCard from './BookingDetailsCard';
import Body from '../../../../components/body/Body';

const MyBookings: React.FC = () => {
    const { fetchTicketList } = useBookingHistoryService();
    const [bookingList, setBookingList] = useState<BookingDetails[]>([]);

    useEffect(() => {
        const fetchBookings = async () => {
            fetchTicketList({
                isDefault: true,
                fromDate: null,
                toDate: null
            }, setBookingList);
        };
        fetchBookings();
    }, []);

    return (
        <Body>
            <h2 className='mb-3'>Your Bookings</h2>

            {bookingList.length === 0 ? (
                <div className="text-center text-gray-500">
                    <p>No bookings found.</p>
                </div>
            ) : (
                <div className="space-y-6">
                    {bookingList.map((booking, index) => (
                        <div key={index}>
                            <BookingDetailsCard booking={booking} />
                        </div>
                    ))}
                </div>
            )}
        </Body>
    );
};

export default MyBookings;
