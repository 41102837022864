import React, { useEffect, useState } from 'react';
import DefaultButton from '../../../../components/buttons/DefaultButton';
import FormControl from '../../../../components/forms/FormControl';
import ModalWrapper from '../../../../components/modals/ModalWrapper';
import { SelectOption } from '../../../../constants/type-constants';
import { dateUtil } from '../../../../utility/dateUtil';
import { InitialModalDetails, InitialVehicleState, ModalDetails, Passenger, Vehicle } from '../ticket-booking-interfaces';
import toast from 'react-hot-toast';
import { TripDeckItem } from '../../../manage/trip-management/trip-interfaces';

interface VehicleModalProps {
    vehicleList: Vehicle[]
    setVehicleList: React.Dispatch<React.SetStateAction<Vehicle[]>>
    modalDetails: ModalDetails
    setModalDetails: React.Dispatch<React.SetStateAction<ModalDetails>>
    passengerList: Passenger[]
    vehicleOptions: SelectOption[]
    tripDeckItems: TripDeckItem[]
}

const VehicleModal: React.FC<VehicleModalProps> = ({ ...props }) => {

    const [vehicleForm, setVehicleForm] = useState<Vehicle>(InitialVehicleState);
    const [passengerOptions, setPassengerOptions] = useState<SelectOption[]>([]);

    const [vehicleModalOpen, setVehicleModalOpen] = useState(false);
    const handleModalOpen = () => {
        if (props.vehicleOptions.length === 0) {
            toast.error("No slots available to book.");
            return;
        }
        if (passengerOptions.length === 0 && props.modalDetails.action === 'add') {
            toast.error("Add a passenger to proceed further.");
            return;
        }
        setVehicleModalOpen(true);
    }
    const handleModalClose = () => {
        props.setModalDetails(InitialModalDetails);
        setVehicleModalOpen(false)
    };

    useEffect(() => {
        if (props.modalDetails.itemType == 'vehicle') {
            if (props.modalDetails.action == 'add') {
                const id = dateUtil.getTimestamp().toString();

                const deckItem = props.tripDeckItems.find(item => item.item.itemType == props.vehicleOptions[0]?.value);
                let item = { id: '', itemType: '' }
                deckItem && (item = { id: deckItem.item.id, itemType: deckItem.item.itemType })

                setVehicleForm({
                    id: id,
                    model: '',
                    regNo: '',
                    item: item,
                    vehicleType: props.vehicleOptions[0]?.value,
                    passengerUniqueId: passengerOptions[0]?.value,
                    ownerName: passengerOptions[0]?.label,
                })
            } else if (props.modalDetails.action === 'edit' ) {
                const vehicle = props.vehicleList.find(vehicle => vehicle.id === props.modalDetails.id );
                if (vehicle) {
                    setPassengerOptions([
                        {
                            value: vehicle.passengerUniqueId,
                            label: vehicle.ownerName
                        },
                        ...passengerOptions
                    ])
                    setVehicleForm(vehicle);
                } else {
                    toast.error("Vehicle not found!");
                }
            }
            handleModalOpen();
        }
    }, [props.modalDetails])

    useEffect(() => {
        const options: SelectOption[] = [];
    
        props.passengerList.forEach((passenger) => {
            const vehicle = props.vehicleList.find(vehicle => vehicle.passengerUniqueId === passenger.uniqueId);
    
            if (!vehicle) {
                options.push({
                    value: passenger.uniqueId,
                    label: passenger.passengerName,
                });
            }
        });
    
        setPassengerOptions(options);
        
    }, [props.passengerList, props.vehicleList]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { value, name } = e.target;

        setVehicleForm((prevForm) => ({
            ...prevForm,
            [name]: value
        }));

        if (name === 'passengerUniqueId') {
            setVehicleForm((prevForm) => ({
                ...prevForm,
                ownerName: passengerOptions.find(option => option.value === value)?.label || ''
            }));
        }

        if (name === 'vehicleType') {
            const deckItem = props.tripDeckItems.find(item => item.item.itemType === value);
            let item = { id: '', itemType: '' }
            deckItem && (item = { id: deckItem.item.id, itemType: deckItem.item.itemType })
            setVehicleForm((prevForm) => ({
                ...prevForm,
                item: item
            }));
        }

    }

    const handleSaveClick = () => {

        const vehicleIndex = props.vehicleList.findIndex(p => p.id == vehicleForm.id);
        if (vehicleIndex != -1) {
            const updatedVehicleList = [...props.vehicleList];
            updatedVehicleList[vehicleIndex] = vehicleForm;
            props.setVehicleList(updatedVehicleList);
            toast.success('Vehicle Details Updated');
        } else {
            props.setVehicleList([
                ...props.vehicleList,
                vehicleForm
            ]);
            toast.success('Vehicle Added');
        }
        handleModalClose();
    }

    return (
        <ModalWrapper
            open={vehicleModalOpen}
            handleClose={handleModalClose}
            title={'Vehicle Details'} >
            <div id='vehicleForm' className='flex flex-wrap w-full'>
                <div className='w-full mb-3 lg:w-1/2 lg:pr-4'>
                    <FormControl
                        label='Vehicle Type'
                        type={'select'}
                        id={'vehicleType'}
                        options={props.vehicleOptions}
                        value={vehicleForm.vehicleType}
                        onChange={handleInputChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='w-full mb-3 lg:w-1/2'>
                    <FormControl
                        label='Owner'
                        type={'select'}
                        id={'passengerUniqueId'}
                        value={vehicleForm.passengerUniqueId}
                        options={passengerOptions}
                        onChange={handleInputChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='w-full mb-3 lg:w-1/2 lg:pr-4'>
                    <FormControl
                        label='Vehicle Model'
                        type={'text'}
                        id={'model'}
                        value={vehicleForm.model}
                        onChange={handleInputChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='w-full mb-3 lg:w-1/2'>
                    <FormControl
                        label='Registration No'
                        type={'text'}
                        id={'regNo'}
                        value={vehicleForm.regNo}
                        onChange={handleInputChange}
                        mandatoryField={true}
                    />
                </div>
                <div className='flex justify-center w-full mt-3'>
                    <DefaultButton
                        onClick={handleSaveClick}
                        buttonText={"Save Vehicle Details"}
                    />
                </div>
            </div>
        </ModalWrapper >
    )
}

export default VehicleModal;