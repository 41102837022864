import { BookingTicketTypes } from '../../payment/order-details-interfaces';

export default function useBookingTicketDetailsService() {
    const getTableData = (type: BookingTicketTypes) => {
        const headersMap = {
            passengers: [
                { name: 'ticketNo', label: 'Ticket No' },
                { name: 'passengerName', label: 'Name' },
                { name: 'gender', label: 'Gender' },
                { name: 'age', label: 'Age' },
                { name: 'pwd', label: 'Is Pwd?' },
                { name: 'rate', label: 'Price' }
            ],
            vehicles: [
                { name: 'ticketNo', label: 'Ticket No' },
                { name: 'item.itemType', label: 'Vehicle Type' },
                { name: 'regNo', label: 'Reg No' },
                { name: 'model', label: 'Model' },
                { name: 'ownerName', label: 'Owner Name' },
                { name: 'rate', label: '' }
            ],
            goods: [
                { name: 'ticketNo', label: 'Ticket No' },
                { name: 'description', label: 'Description' },
                { name: 'weight', label: 'Weight' },
                { name: 'rate', label: '' }
            ]
        };

        const titlesMap = {
            passengers: 'Passengers',
            vehicles: 'Vehicles',
            goods: 'Goods'
        };

        return {
            title: titlesMap[type],
            headers: headersMap[type]
        }
    }

    return {
        getTableData
    }
}
