import API_ENDPOINT from "../constants/api-constants";
import { useApiService } from "./useApiService";
import { useHandleErrorService } from "./useHandleErrorService";
import { AxiosResponse } from "axios";
import { SelectOption } from "../constants/type-constants";
import { Division } from "../pages/manage/division-management/division-interfaces";
import { Ghat } from "../pages/manage/ghat-management/ghat-interfaces";
import { SelectedDaysMap, TripDay, Vessel } from "../pages/manage/vessel-management/vessel-interfaces";
import { ItemType } from "../pages/manage/vessel-layout/vessel-layout-interfaces";
import { Route } from "../pages/manage/route-management/route-interfaces";
import toast from "react-hot-toast";
import { SelectedMonthsMap } from "../pages/manage/trip-settings/trip-settings-interfaces";
// import { ItemType } from "../pages/manage/vessel-matrix/vessel-layout-interfaces";

export const useCommonService = () => {
    const { fetchPublicRequest } = useApiService();
    const { handleError } = useHandleErrorService();

    // VESSEL TYPE OPTIONS FROM ENUM (e.g., Ferry, Ropax, etc/)
    const fetchVesselTypeOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                API_ENDPOINT.COMMON.ENUM.VESSEL_TYPES
            );
            const { object: vesselTypes } = response.data;
            const options: SelectOption[] = [];
            vesselTypes.forEach((item: string) => {
                options.push({
                    value: item,
                    label: item
                })
            });

            updateState(options);
            if (setOption) {
                setOption(options[0].value);
            }
        } catch (error) {
            handleError(error);
        }
    }

    const fetchUnitTypes = async () => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                API_ENDPOINT.COMMON.ENUM.UNIT_TYPES
            );

            if (response) {
                return response;
            }
            return null;
        } catch (error) {
            handleError(error);
        }
    }

    // STATUS OPTIONS FROM ENUM (e.g., Active, Inactive, Deleted, etc.)
    const fetchStatusOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                API_ENDPOINT.COMMON.ENUM.STATUS_LIST
            );

            const { object } = response.data;

            const options: SelectOption[] = [];
            object.forEach((item: string) => {
                if (item != 'Deleted') {
                    options.push({
                        value: item,
                        label: item
                    });
                }
            })
            updateState(options);
            if (setOption) {
                setOption(options[0]?.value || '');
            }
        } catch (error) {
            handleError(error);
        }
    }

    // SERVICE TYPE OPTIONS FROM ENUM (e.g., Day, Night, etc)
    const fetchServiceTypeOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                API_ENDPOINT.COMMON.ENUM.SERVICE_TYPES
            );
            const { object } = response.data;
            const options: any = [];
            object.forEach((item: string) => {
                options.push({
                    value: item,
                    label: item
                });
            })

            updateState(options);
            if (setOption) {
                setOption(options[0].value);
            }
            return [];
        } catch (error) {
            handleError(error);
        }
    }

    // PWD OPTIONS FROM ENUM (Yes, No)
    const fetchPwd = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                API_ENDPOINT.COMMON.ENUM.PWD
            );
            const { object } = response.data;

            const options: SelectOption[] = [];
            object.forEach((item: string) => {
                options.push({
                    value: item,
                    label: item
                });
            })

            updateState(options);
            if (setOption) {
                setOption(options[0].value);
            }
        } catch (error) {
            handleError(error);
        }
    }

    // OWNERS OPTIONS FROM ENUM (e.g., Govt, Private, etc)
    const fetchOwnerOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                API_ENDPOINT.COMMON.ENUM.OWNERS
            );

            const { object: owners } = response.data;
            const options: any = [];
            owners.forEach((item: string) => {
                options.push({
                    value: item,
                    label: item
                });
            })

            updateState(options);
            if (setOption) {
                setOption(options[0].value);
            }
        } catch (error) {
            handleError(error);
        }
    }

    // NIGHT NAVIGATION OPTIONS FROM ENUM (Yes/No) 
    const fetchNightNavigationOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                API_ENDPOINT.COMMON.ENUM.NIGHT_NAVIGATION
            );

            const { object: nightNavigations } = response.data;

            const options: any = [];
            nightNavigations.forEach((item: string) => {
                options.push({
                    value: item,
                    label: item
                });
            })

            updateState(options);
            if (setOption) {
                setOption(options[0].value);
            }
        } catch (error) {
            handleError(error);
        }
    }

    // MONTH OPTIONS FROM ENUM 
    const fetchMonthOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setSelectedMonths: React.Dispatch<React.SetStateAction<SelectedMonthsMap>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                API_ENDPOINT.COMMON.ENUM.MONTHS
            );

            const { object: months } = response.data;
            const initialSelection: SelectedMonthsMap = {};

            const options: SelectOption[] = [];
            months.forEach((item: string) => {
                options.push({
                    value: item,
                    label: item.slice(0, 3).charAt(0).toUpperCase() + item.slice(1, 3).toLowerCase()
                });
            })

            updateState(options);
            setSelectedMonths(initialSelection);
        } catch (error) {
            handleError(error);
        }
    }

    const fetchBookingStatus = async () => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                API_ENDPOINT.COMMON.ENUM.BOOKING_STATUS
            );

            if (response) {
                const options: any = [];
                response.data.forEach((item: string) => {
                    options.push({
                        value: item,
                        label: item
                    });
                })

                return options;
            }
            return [];
        } catch (error) {
            handleError(error);
        }
    }

    // GENDER OPTIONS FROM ENUM (e.g., Male, Female, etc)
    const fetchGenders = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        selectOption?: React.Dispatch<React.SetStateAction<string>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                API_ENDPOINT.COMMON.ENUM.GENDERS
            );

            const options: SelectOption[] = [];
            response.data.object.forEach((item: string) => {
                options.push({
                    value: item,
                    label: item
                });
            })

            updateState(options);

            if (selectOption) selectOption(options[0].value);
        } catch (error) {
            handleError(error);
        }
    }

    const fetchDeviceTypes = async () => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                API_ENDPOINT.COMMON.ENUM.DEVICE_TYPES
            );

            if (response) {
                const options: any = [];
                response.data.forEach((item: string) => {
                    options.push({
                        value: item,
                        label: item
                    });
                })

                return options;
            }
            return [];
        } catch (error) {
            handleError(error);
        }
    }
    // BOOKING MODE OPTIONS FROM ENUM (Online/Offline) 
    const fetchBookingModeOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                API_ENDPOINT.COMMON.ENUM.BOOKING_MODES
            );

            const { object } = response.data;
            const options: SelectOption[] = [];
            object.forEach((item: string) => {
                options.push({
                    value: item,
                    label: item
                });
            })

            updateState(options);
            if (setOption) {
                setOption(options[0].value);
            }

        } catch (error) {
            handleError(error);
        }
    }

    // ================================================================================================
    // ===================================== MASTER ===================================================
    // ================================================================================================

    const fetchWeekDayOptions = async (
        setWeekdays: React.Dispatch<React.SetStateAction<TripDay[]>>,
        setSelectedDays: React.Dispatch<React.SetStateAction<SelectedDaysMap>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                API_ENDPOINT.COMMON.ENUM.WEEKDAYS
            );

            const { object: weekdays } = response.data;

            const initialSelection: SelectedDaysMap = {};
            const tripDays: TripDay[] = [];
            weekdays.forEach((day: string, index: number) => {
                initialSelection[index] = false;
                tripDays.push({
                    id: index.toString(),
                    dayName: day
                });
            });

            setWeekdays(tripDays);
            setSelectedDays(initialSelection);

        } catch (error) {
            handleError(error);
        }
    }

    const fetchItemOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                API_ENDPOINT.COMMON.MASTER.GET_ALL_ITEMS
            );

            const { object: items } = response.data;
            const itemList: SelectOption[] = []
            items.forEach((item: ItemType) => {
                if (item.itemType == "Unreserved") {
                    itemList.unshift({
                        value: item.id,
                        label: item.itemType
                    });
                } else {
                    itemList.push({
                        value: item.id,
                        label: item.itemType
                    });
                }

            });
            updateState(itemList);

            if (setOption) {
                setOption(itemList[0].value);
            }
        } catch (error) {
            handleError(error);
        }
    }

    // DECK OPTIONS FROM ENUM (Deck No 1, Deck No 2, etc)
    const fetchDeckOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                API_ENDPOINT.COMMON.MASTER.GET_ALL_DECKS
            );
            const { object: decks } = response.data;

            const options: SelectOption[] = [];
            decks.forEach((item: any, index: number) => {
                options.push({
                    value: item.id,
                    label: item.deckNo
                });
            })

            updateState(options);
            if (setOption) {
                setOption(options[0].value);
            }
        } catch (error) {
            handleError(error);
        }
    }

    const fetchDivisionOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                API_ENDPOINT.COMMON.MASTER.GET_ACTIVE_DIVISIONS
            )
            const { object } = response.data;
            const options: SelectOption[] = [];

            object.forEach((division: Division) => {
                options.push({
                    value: division.id,
                    label: division?.divisionName
                });
            });

            updateState(options);
            if (setOption) {
                setOption(options[0].value);
            }
        } catch (error) {
            handleError(error);
        }
    }

    const fetchGhatOptions = async (
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                API_ENDPOINT.COMMON.MASTER.GET_ACTIVE_GHATS
            )

            const { object } = response.data;
            const options: SelectOption[] = [];
            object.forEach((ghat: Ghat) => {
                options.push({
                    value: ghat.id,
                    label: ghat.ghatName
                });
            })

            updateState(options);
            if (setOption) {
                setOption(options[0].value);
            }
        } catch (error) {
            handleError(error);
        }
    }

    const fetchGhatOptionsByDivision = async (
        divisionId: string,
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                API_ENDPOINT.COMMON.MASTER.GET_ACTIVE_GHATS_BY_DIVISION,
                { divisionId: divisionId }
            )
            const { object } = response.data;

            const options: SelectOption[] = [];
            object.forEach((ghat: Ghat) => {
                options.push({
                    value: ghat.id,
                    label: ghat.ghatName
                });
            })

            updateState(options);

            if (setOption) {
                setOption(options[0].value);
            }

        } catch (error) {
            handleError(error);
        }
    }

    const fetchRoutesByDivision = async (
        divisionId: string,
        setRoutes: React.Dispatch<React.SetStateAction<Route[]>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                API_ENDPOINT.COMMON.MASTER.GET_ACTIVE_ROUTES_BY_DIVISION,
                { divisionId: divisionId }
            );
            const { object: routes } = response.data;
            if (routes.length) {
                setRoutes(routes);
                return;
            }
            toast.error("No active routes found under the selected division");
            setRoutes([]);

        } catch (error) {
            handleError(error);
        }
    }

    const fetchVesselOptionsByDivision = async (
        divisionId: string,
        updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setOption?: React.Dispatch<React.SetStateAction<string>>
    ) => {
        try {
            const response: AxiosResponse = await fetchPublicRequest(
                API_ENDPOINT.COMMON.MASTER.GET_ACTIVE_VESSELS_BY_DIVISION,
                { divisionId: divisionId }
            )
            const { object } = response.data;

            const options: SelectOption[] = [];
            object.forEach((vessel: Vessel) => {
                options.push({
                    value: vessel.id,
                    label: vessel.name
                });
            })

            updateState(options);

            if (setOption) {
                setOption(options[0]?.value);
            }

        } catch (error) {
            handleError(error);
        }
    }

    return {
        fetchDivisionOptions,
        fetchGhatOptions,
        fetchGhatOptionsByDivision,
        fetchVesselTypeOptions,
        fetchStatusOptions,
        fetchOwnerOptions,
        fetchNightNavigationOptions,
        fetchMonthOptions,
        fetchWeekDayOptions,
        fetchDeckOptions,
        fetchItemOptions,
        fetchBookingModeOptions,
        fetchServiceTypeOptions,
        fetchRoutesByDivision,
        fetchVesselOptionsByDivision,
        fetchBookingStatus,
        fetchPwd,

        fetchUnitTypes,
        fetchGenders,
        fetchDeviceTypes,
    }

}

