import { FileDownloadOutlined } from '@mui/icons-material';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Body from '../../../../components/body/Body';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import Card from '../../../../components/cards/Card';
import { BookingTicketTypes, GoodsView, PassengerView, VehicleView } from '../../payment/order-details-interfaces';
import TicketItemDetailsTable from '../../payment/sections/TicketItemDetailsTable';
import { IBookingTicketDetails } from './booking-ticket-details-interfaces';
import PaymentInfo from './sections/PaymentInfo';
import TicketHeader from './sections/TicketHeader';
import TripVesselInfo from './sections/TripVesselInfo';
import useBookingTicketDetailsService from './useBookingTicketDetailsService';
import useTicketGenerationService from './useTicketGenerationService';

const BookingTicketDetails: React.FC = () => {
    const location = useLocation();
    const bookingTicketDetails = location.state.bookingTicketDetails as IBookingTicketDetails;

    const { generateTicketPdf } = useTicketGenerationService();

    const { getTableData } = useBookingTicketDetailsService();

    const renderTable = (type: BookingTicketTypes) => {
        const tickets = bookingTicketDetails[type];
        if (!tickets || tickets.length === 0) return null;
        const { title, headers } = getTableData(type);
        return (
            <TicketItemDetailsTable
                title={title}
                headers={headers}
                tickets={tickets}
            />
        )
    }

    const handleDownloadClick = async () => {
        generateTicketPdf(bookingTicketDetails);
    };

    return (
        <Body>
            <h2 className='mb-3'>Ticket Details</h2>

            <Card>
                <>
                    {/* Header */}
                    <TicketHeader
                        bookingTicketDetails={bookingTicketDetails}
                        button={<PrimaryButton
                            onClick={handleDownloadClick}
                            buttonText={'Download Ticket'}
                            buttonIcon={FileDownloadOutlined}
                        />}
                    />

                    {/* Trip and Vessel Information */}
                    <TripVesselInfo bookingTicketDetails={bookingTicketDetails} />

                    {/* Ticket Tables */}
                    {renderTable('passengers')}
                    {renderTable('vehicles')}
                    {renderTable('goods')}

                    {/* Payment Information */}
                    <PaymentInfo bookingTicketDetails={bookingTicketDetails} />

                    {/* Footer Placeholder */}
                    <div className="text-gray-500 text-sm text-justify mt-10">
                        *Please ensure you arrive at the boarding point at least 15 minutes before departure. Ticket holders are responsible for checking all travel details before the trip. For any inquiries or assistance, contact our customer support. Safe travels and thank you for choosing our service! Please ensure you arrive at the boarding point at least 15 minutes before departure. Ticket holders are responsible for checking all travel details before the trip. For any inquiries or assistance, contact our customer support. Safe travels and thank you for choosing our service!
                    </div>
                </>
            </Card>
        </Body>
    );
};

export default BookingTicketDetails;
