import React from 'react'
import { IndividualTicket } from '../order-details-interfaces';

interface BookingInvoiceProps {
    ticketList: IndividualTicket[];
    grossAmount: number;
    convenienceFee: number;
    netAmount: number;
    submitButton: React.ReactElement
}

const BookingInvoice: React.FC<BookingInvoiceProps> = ({ ...props }) => {
    return (
        <div>
            <h4>Invoice(₹)</h4>
            <section className="mb-6 border-b pb-6">
                <table className='w-full'>
                    <thead>
                        <tr>
                            <th className='w-auto text-left font-medium'>Ticket No</th>
                            <th className='w-auto text-right font-medium'>Price</th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.ticketList.map((ticket, index) => (
                            <tr key={index}>
                                <td>{ticket.ticketNo}</td>
                                <td className='text-right'>{ticket.price}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </section>
            <section className="mb-6 border-b pb-6">
                <div className="flex justify-between mb-2">
                    <span>Net Amount</span>
                    <span>{props.grossAmount}</span>
                </div>
                <div className="flex justify-between mb-2">
                    <span>Convenience Fee</span>
                    <span>{props.convenienceFee}</span>
                </div>
                <div className="flex justify-between font-bold">
                    <span>Total</span>
                    <span>{props.netAmount}</span>
                </div>
            </section>
            <section>
                <div className="mb-6 text-xs text-gray-500 text-justify leading-5 border rounded px-4 py-2 border-red-300">
                    Disclaimer: By clicking &quot;Proceed to Payment&quot;, you will be redirected to our secure payment gateway to complete your booking. Please ensure all booking details are correct before proceeding.
                    Once payment is completed, you will be redirected back to our site with your confirmed booking details. For any issues, contact our support team.
                </div>
                {props.submitButton}
            </section>
        </div>
    )
}

export default BookingInvoice;