import { BorderColor } from '@mui/icons-material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import React from 'react'
import TableActionButton from '../../../components/buttons/TableActionButton'
import DeckItems from '../../../components/deck-items/DeckItems'
import { TripSettingsTableRow } from './trip-settings-interfaces'

interface TripTableProps {
    tripSettingsTableRows: TripSettingsTableRow[]
    handleSettingsEditClick: (id: string) => void
}

const TripSettingsTable: React.FC<TripTableProps> = ({ tripSettingsTableRows, handleSettingsEditClick }) => {

    const tripSettingsTableCols: GridColDef[] = [
        { field: 'slNo', headerName: 'Sl No' },
        { field: 'vessel', headerName: 'Vessel', flex: 2 },
        { field: 'departureTime', headerName: 'Dept. Time', flex: 2 },
        { field: 'tripDuration', headerName: 'Duration', flex: 2 },
        { field: 'dockNo', headerName: 'Dock No', flex: 2 },
        { field: 'serviceType', headerName: 'Service Type', flex: 2 },
        { field: 'status', headerName: 'Status', flex: 1 },
        {
            align: 'center',
            flex: 2,
            minWidth: 150,
            headerAlign: 'center',
            field: 'capacity',
            headerName: 'Capacity',
            sortable: false,
            filterable: false,
            renderCell: params => {
                const { deckItems } = params.row;
                return (<DeckItems items={deckItems} />);
            },
        },
        {
            align: 'center',
            flex: 2,
            minWidth: 150,
            headerAlign: 'center',
            field: 'action',
            headerName: 'Action',
            sortable: false,
            filterable: false,
            renderCell: params => {
                const { id } = params.row;
                return (
                    <div className='flex gap-2 justify-center items-center h-full'>
                        <TableActionButton
                            type='primary'
                            onClick={async () => handleSettingsEditClick(id)}>
                            <BorderColor fontSize='small' />
                        </TableActionButton>
                    </div>
                );
            },
        }
    ]

    return (
        <div className='mt-3 bg-white'>
            <div className='flex justify-center'>
                <div className='flex w-full'>
                    <DataGrid
                        rows={tripSettingsTableRows}
                        columns={tripSettingsTableCols}
                        autosizeOnMount
                        autoHeight
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[5, 10, 15]}
                    />
                </div>
            </div>
        </div>
    )
}

export default TripSettingsTable