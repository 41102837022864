import toast from "react-hot-toast";
import API_ENDPOINT from "../../../constants/api-constants";
import { SelectOption } from "../../../constants/type-constants";
import { useApiService } from "../../../hooks/useApiService";
import { useHandleErrorService } from "../../../hooks/useHandleErrorService";
import { Route, RoutePost, RouteTableRows } from "./route-interfaces";
import { AxiosResponse } from "axios";
import Swal from "sweetalert2";
import swalConfig from "../../../config/swalConfig";

export const useRouteManagementService = () => {

    const { fetchAuthRequest, postAuthRequest } = useApiService();
    const { handleError } = useHandleErrorService();

    const handleGhatInputChange = (
        updateState: React.Dispatch<React.SetStateAction<string>>,
        siblingState: string,
        siblingList: SelectOption[],
        setSiblingOptions: React.Dispatch<React.SetStateAction<SelectOption[]>>,
    ) =>
        (event: React.ChangeEvent<HTMLSelectElement>) => {
            const value = event.target.value;
            if (value == siblingState) {
                toast.error("Origin and Destination cannot be same.")
            } else {
                updateState(value);
                const filteredOptions = siblingList.filter((option: SelectOption) => option.value != value);
                setSiblingOptions(filteredOptions);
            }
            updateState(value);
        };

    const handleInputChange = (updateState: React.Dispatch<React.SetStateAction<string>>) =>
        (event: React.ChangeEvent<HTMLSelectElement>) => {
            updateState(event.target.value);
        };

    const initializeOptions = (
        ghatFromList: SelectOption[],
        ghatToList: SelectOption[],
        setGhatFrom: React.Dispatch<React.SetStateAction<string>>,
        setGhatTo: React.Dispatch<React.SetStateAction<string>>,
        setGhatFromOptions: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setGhatToOptions: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        ghatFromInitial?: string,
        ghatToInitial?: string
    ) => {
        let initialGhatFrom = ghatFromInitial;
        let initialGhatTo = ghatToInitial;

        if (!ghatFromInitial && ghatFromList.length > 0) {
            initialGhatFrom = ghatFromList[0].value;
        }
        if (!ghatToInitial && ghatToList.length > 0) {
            initialGhatTo = ghatToList.find(ghat => ghat.value !== initialGhatFrom)?.value || '';
        }

        if (initialGhatFrom) setGhatFrom(initialGhatFrom);
        if (initialGhatTo) setGhatTo(initialGhatTo);

        const filteredGhatFromOptions = ghatFromList.filter(option => option.value !== initialGhatTo);
        const filteredGhatToOptions = ghatToList.filter(option => option.value !== initialGhatFrom);

        setGhatFromOptions(filteredGhatFromOptions);
        setGhatToOptions(filteredGhatToOptions);
    };

    const fetchRoutes = async (
        division: string,
        setTableRows: React.Dispatch<React.SetStateAction<RouteTableRows[]>>
    ) => {
        try {
            const response: AxiosResponse = await fetchAuthRequest(
                API_ENDPOINT.MANAGE.ROUTE.GET_ROUTES_BY_DIVISION,
                {
                    divisionId: division
                }
            );

            const { object } = response.data;

            const rows: RouteTableRows[] = [];

            object.forEach((route: Route, index: number) => {
                rows.push({
                    slNo: index + 1,
                    id: route.id,
                    ghatFromId: route.ghatFrom.id,
                    ghatToId: route.ghatTo.id,
                    ghatFrom: route.ghatFrom.ghatName,
                    ghatTo: route.ghatTo.ghatName,
                    status: route.status
                })
            });

            setTableRows(rows);

        } catch (error) {
            handleError(error);
        }
    }

    const saveRoute = async (route: RoutePost) => {
        let saved = false;
        const save = async () => {
            try {
                const response: AxiosResponse = await postAuthRequest(
                    API_ENDPOINT.MANAGE.ROUTE.SAVE_ROUTE,
                    route
                );
                const { message } = response.data;
                toast.success(message);
                saved = true
            } catch (error) {
                handleError(error);
            }
            return saved;
        }
        if (route.status == 'Deleted') {
            await Swal.fire(swalConfig({
                title: 'Are you sure you want to delete this route?',
                icon: 'question',
                confirmButtonText: 'Delete',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                position: 'center'
            })).then(async (result) => {
                if (result.isConfirmed) {
                    await save();
                }
            });
        } else {
            await save();
        }

        return saved;
    }

    const deleteRoute = async (postData: RoutePost) => {
        let deleted = false;
        await Swal.fire(swalConfig({
            title: 'Are you sure you want to delete this route?',
            icon: 'question',
            confirmButtonText: 'Delete',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            position: 'center'
        })).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response: AxiosResponse = await postAuthRequest(
                        API_ENDPOINT.MANAGE.ROUTE.SAVE_ROUTE,
                        postData
                    );
                    const { message } = response.data;
                    toast.success(message);
                    deleted = true
                } catch (error) {
                    handleError(error);
                }
            }
        });

        return deleted;
    }

    return {
        handleInputChange,
        handleGhatInputChange,
        initializeOptions,
        fetchRoutes,
        saveRoute,
        deleteRoute
    }
} 