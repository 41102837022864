import React from 'react'
import { VesselDeckItem } from '../../pages/manage/vessel-management/vessel-interfaces';
import PopoverDetails from '../popovers/PopoverDetails';
import { TripDeckItem } from '../../pages/manage/trip-management/trip-interfaces';

export interface GroupedDeckItem {
    deckId: string;
    deckNo: string;
    items: Array<{
        itemType: string;
        unitType: string;
        capacityValue: string;
    }>;
}

export const groupItemsByDeck = (items: VesselDeckItem[]) => {
    const grouped = items.reduce<Record<string, GroupedDeckItem>>((acc, item) => {
        const deckId = item.deck.id;
        if (!acc[deckId]) {
            acc[deckId] = {
                deckId: deckId,
                deckNo: item.deck.deckNo,
                items: []
            };
        }
        acc[deckId].items.push({
            itemType: item.item.itemType,
            unitType: item.item.unitType,
            capacityValue: item.capacityValue
        });
        return acc;
    }, {});

    return Object.values(grouped);
}

const DeckItems: React.FC<{ items: VesselDeckItem[] | TripDeckItem[] }> = ({ items }) => {
    const deckItems = groupItemsByDeck(items);

    return (
        deckItems.length > 0 ?
            <div className='flex gap-2 justify-center items-center h-full'>
                <PopoverDetails buttonText={'View Capacity'}>
                    <table className='w-full'>
                        <tbody className='text-sm'>
                            {
                                deckItems.map((deck: GroupedDeckItem) => (
                                    <React.Fragment key={deck.deckId}>
                                        <tr className='border bg-sky-100'>
                                            <td colSpan={2} className='px-2 py-1'>{deck.deckNo}</td>
                                        </tr>
                                        {
                                            deck.items.map(item => (
                                                <tr key={item.itemType}>
                                                    <td className='w-3/4 border px-2 py-1'>{item.itemType} ({item.unitType})</td>
                                                    <td className='w-1/4 border px-2 py-1'>{item.capacityValue}</td>
                                                </tr>
                                            ))
                                        }
                                    </React.Fragment>
                                ))
                            }
                        </tbody>
                    </table>
                </PopoverDetails>
            </div>
            :
            <div className='text-red-600'>Not Available</div>
    )
}

export default DeckItems