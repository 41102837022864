import React from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { ROUTES } from "../constants/routes-constants";
import useAuth from "../hooks/useAuth";
import { store } from "../store/reducers/store";
import Loading from "../pages/common/Loading";

interface RequireAuthProps {
    allowedRoles: string[];
}

const RequireAuth: React.FC<RequireAuthProps> = ({ allowedRoles }) => {
    const { auth } = useAuth();
    const { data } = store.getState();
    const location = useLocation();

    if (auth?.accessToken === '' && data.isAuthenticated) {
        return <Loading />;
    }

    return (
        auth?.roles?.some(role => allowedRoles.includes(role))
            ? <Outlet />
            : auth?.user
                ? <Navigate to={ROUTES.UNAUTHORISED} state={{ from: location }} replace />
                : <Navigate to={ROUTES.AUTH.LOGIN} state={{ from: location }} replace />
    );
};

export default RequireAuth;
