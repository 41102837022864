import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InitialSearchParamState, ISearchParams } from './landing-interfaces';
import { Route } from '../../manage/route-management/route-interfaces';
import { useLandingService } from './useLandingService';
import OrgLogo from '../../../resources/images/aiwtd_logo.png';
import DefaultHeader from '../../../components/headers/DefaultHeader';
import './landing.sass'
import ContentClickable from '../../../components/clickables/ContentClickable';
import { ROUTES } from '../../../constants/routes-constants';
import { TicketIcon } from '@heroicons/react/20/solid';
import DefaultFooter from '../../../components/footers/DefaultFooter';
import { SearchRounded, SwapHorizRounded } from '@mui/icons-material';
import { dateUtil } from '../../../utility/dateUtil';
import GhatSelectModal from './GhatSelectModal';

const Landing: React.FC = () => {

    const { fetchRoutes, routesToGhatList, searchTrips } = useLandingService();
    const navigate = useNavigate();

    const [searchParams, setSearchParams] = useState<ISearchParams>(InitialSearchParamState);
    const [routes, setRoutes] = useState<Route[]>([]);
    const [ghatList, setGhatList] = useState<any[]>([]);

    useEffect(() => { fetchRoutes(setRoutes) }, []);

    const { handleSwapClick } = useLandingService();

    const searchInputRef = useRef<HTMLInputElement>(null);
    const [selectedParameter, setSelectedParameter] = useState('');

    const [ghatOptionModalOpen, setGhatOptionModalOpen] = useState(false);
    const handleGhatOptionModalOpen = () => setGhatOptionModalOpen(true);
    const handleGhatOptionModalClose = () => setGhatOptionModalOpen(false);

    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchParams({
            ...searchParams,
            tripDate: e.target.value
        });
    }

    const showSearchModal = (clickedDiv: string) => {
        setSelectedParameter(clickedDiv);

        routesToGhatList(clickedDiv, routes, searchParams, setGhatList);
        handleGhatOptionModalOpen();
        searchInputRef.current?.focus();
    }

    useEffect(() => { console.log(ghatList) }, [ghatList]);

    const handleSearchClick = async () => {
        const found = await searchTrips(searchParams, routes);
        if (found) navigate(ROUTES.USER.SEARCH_RESULTS);
    }

    return (
        <div className='bg-slate-100'>
            <div className='min-h-screen'>
                <DefaultHeader />
                <div className='hero-section h-full flex items-center justify-center'>
                    <div className="container mt-3 max-w-7xl mx-auto px-4 sm:px-6">
                        <div className="p-4">
                            <div className="flex justify-center">
                                <img className="w-40" src={OrgLogo} alt="" />
                            </div>
                            <div className="text-3xl text-center mb-4 text-sky-700 mt-2">
                                Search Vessels
                            </div>

                            <div className="flex flex-wrap justify-center">
                                <div className="rounded-xl w-full md:w-3/4 lg:w-2/3 outline outline-2 outline-blue-400 flex flex-wrap bg-slate-50">
                                    <div className="w-full lg:w-7/12 flex flex-wrap">
                                        <div className="w-full lg:w-1/2 px-6 bg-transparent">
                                            <div
                                                className="w-full md:text-center text-nowrap text-clip overflow-x-hidden py-3.5"
                                                onClick={() => showSearchModal('origin')}>
                                                {searchParams.origin.originName}
                                            </div>
                                        </div>
                                        <div className="relative bg-slate-400 flex items-center">
                                            <button className="absolute -left-12 top-7 lg:-left-5 lg:top-1.5 flex p-2 bg-zinc-500 text-white rounded-full scale-90 rotate-90 lg:rotate-0"
                                                onClick={() => handleSwapClick(searchParams, setSearchParams)}
                                            >{<SwapHorizRounded />}</button>
                                        </div>
                                        <div className="w-full lg:w-1/2 px-6 bg-transparent">
                                            <div
                                                className="w-full md:text-center text-nowrap text-clip overflow-x-hidden py-3.5"
                                                onClick={() => showSearchModal('destination')}>
                                                {searchParams.destination.destinationName}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-5/12 flex flex-wrap">
                                        <div className="w-full lg:w-7/12 px-6 bg-transparent">
                                            <input
                                                className="w-full md:text-center text-nowrap text-clip overflow-x-hidden py-3.5 bg-transparent"
                                                type="date"
                                                value={searchParams.tripDate}
                                                onChange={handleDateChange}
                                                min={dateUtil.today()}
                                            />
                                        </div>
                                        <div className="w-full lg:w-5/12">
                                            <button
                                                className="w-full justify-center h-full p-3.5 bg-sky-600 text-white font-medium rounded-b-xl lg:rounded-bl-none lg:rounded-r-xl flex gap-2 pl-5 items-center outline outline-1 outline-sky-600"
                                                onClick={handleSearchClick}>
                                                Search <SearchRounded />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mt-4 mb-4 text-slate-700 font-medium">
                                Assam Inland Water Transport Development Society
                            </div>
                            <div className="w-full md:w-2/3 lg:w-1/2 mx-auto">
                                <hr className="border-slate-300 border-1" />
                            </div>
                            <div className="text-center mt-4 mb-4 text-slate-700 w-full lg:w-1/2 flex mx-auto text-sm">
                                Unlock exclusive discounts with our Bulk Booking feature. Purchase tickets in bulk for your favorite routes and sail hassle-free with every trip
                            </div>
                            <div className="flex justify-center p-2 gap-4">
                                <ContentClickable
                                    href={ROUTES.USER.BUNDLE_TICKET_BOOK}
                                    icon={<TicketIcon />}
                                    header="Route Ticket Bundle"
                                    text="Unlock Savings on Your Frequent Routes"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {/* OTHER CONTENTS INSIDE THE DIV BELOW */}
                {/* <div className="container mt-3 max-w-7xl mx-auto px-4 sm:px-6">
                        <Card addClass={"mt-4"}>
                            <div>
                                <div className="text-lg font-semibold">
                                    Recent Bookings
                                </div>
                                <div>
                                    This section is under construction. Check again later.

                                    <button onClick={() => navigate(ROUTES.USER.MY_BOOKINGS)}>Your Bookings</button>
                                </div>
                            </div>
                        </Card>
                    </div> */}
            </div>
            <DefaultFooter />

            <GhatSelectModal
                ghatOptionModalOpen={ghatOptionModalOpen}
                handleGhatOptionModalClose={handleGhatOptionModalClose}
                searchInputRef={searchInputRef}
                ghatList={ghatList}
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                selectedParameter={selectedParameter}
                setSelectedParameter={setSelectedParameter}
            />
        </div>
    )
}

export default Landing;