import React, { useEffect, useState } from 'react'
import { Route } from '../route-management/route-interfaces'
import FormControl from '../../../components/forms/FormControl'
import ModalWrapper from '../../../components/modals/ModalWrapper'
import { SelectOption } from '../../../constants/type-constants'
import { useTripSettingsService } from './useTripSettingsService'
import DefaultButton from '../../../components/buttons/DefaultButton'
import { useCommonService } from '../../../hooks/useCommonService'
import { SelectedMonthsMap, TripSettingsPost, TripSettingsTableRow, TripSettingsView } from './trip-settings-interfaces'

interface TripSettingsModalProps {
    tripSettings: any
    routes: Route[]
    division: string
    divisionOptions: SelectOption[]
    monthOptions: SelectOption[]
    selectedMonths: SelectedMonthsMap
    setSelectedMonths: React.Dispatch<React.SetStateAction<SelectedMonthsMap>>
    tripModalOpen: boolean
    handleTripModalOpen: () => void
    handleTripModalClose: () => void
    ghatFrom: string
    ghatTo: string
    setTripSettingsTableRows: React.Dispatch<React.SetStateAction<TripSettingsTableRow[]>>
    setTripList: React.Dispatch<React.SetStateAction<TripSettingsView[]>>
}

const TripSettingsModal: React.FC<TripSettingsModalProps> = (
    {
        tripSettings,
        routes,
        division,
        divisionOptions,
        monthOptions,
        selectedMonths,
        setSelectedMonths,
        tripModalOpen,
        handleTripModalOpen,
        handleTripModalClose,
        ghatFrom,
        ghatTo,
        setTripSettingsTableRows,
        setTripList,
    }) => {

    const {
        fetchServiceTypeOptions,
        fetchVesselOptionsByDivision,
        fetchStatusOptions,
        fetchBookingModeOptions,
        fetchRoutesByDivision,
    } = useCommonService();

    const {
        handleInputChange,
        handleCheckboxChange,
        generateGhatFromOptions,
        generateGhatToOptions,
        fetchTripSettings,
        saveTripSettings
    } = useTripSettingsService();

    const [modalRoutes, setModalRoutes] = useState<Route[]>([]);

    const [serviceTypeOptions, setServiceTypeOptions] = useState<SelectOption[]>([]);
    const [vesselOptions, setVesselOptions] = useState<SelectOption[]>([]);
    const [statusOptions, setStatusOptions] = useState<SelectOption[]>([]);
    const [bookingModeOptions, setBookingModeOptions] = useState<SelectOption[]>([]);
    const [formGhatFromOptions, setFormGhatFromOptions] = useState<SelectOption[]>([]);
    const [formGhatToOptions, setFormGhatToOptions] = useState<SelectOption[]>([]);

    const [trip, setTrip] = useState('');
    const [formDivision, setFormDivision] = useState('');
    const [formGhatFrom, setFormGhatFrom] = useState('');
    const [formGhatTo, setFormGhatTo] = useState('');
    const [serviceType, setServiceType] = useState('');
    const [vessel, setVessel] = useState('');
    const [departureHour, setDepartureHour] = useState('');
    const [departureMin, setDepartureMin] = useState('');
    const [departureSec, setDepartureSec] = useState('');
    const [tripDurationHour, setTripDurationHour] = useState('');
    const [tripDurationMin, setTripDurationMin] = useState('');
    const [tripDurationSec, setTripDurationSec] = useState('');
    const [tripStatus, setTripStatus] = useState('');
    const [dockNo, setDockNo] = useState('');
    const [bookingMode, setBookingMode] = useState('');
    const [activateBefore, setActivateBefore] = useState('');
    const [onlineSeat, setOnlineSeat] = useState('');
    const [offlineSeat, setOfflineSeat] = useState('');
    // ------------------------------------------------

    useEffect(() => {
        setFormDivision(division);
    }, [division, tripModalOpen]);

    useEffect(() => {
        setModalRoutes(routes);
    }, [routes])

    useEffect(() => {
        if (formDivision && formDivision !== '') {
            fetchRoutesByDivision(formDivision, setModalRoutes);
        }
    }, [formDivision]);

    useEffect(() => {
        if (formDivision != '') {
            fetchStatusOptions(setStatusOptions, setTripStatus);
            fetchBookingModeOptions(setBookingModeOptions, setBookingMode);
            fetchServiceTypeOptions(setServiceTypeOptions, setServiceType);
            fetchVesselOptionsByDivision(formDivision, setVesselOptions, setVessel);
        }
    }, [formDivision]);

    useEffect(() => {
        if (modalRoutes.length == 0) {
            setFormGhatFromOptions([]);
            setFormGhatToOptions([]);
        } else {
            generateGhatFromOptions(modalRoutes, setFormGhatFromOptions, setFormGhatFrom);
        }
    }, [modalRoutes]);

    useEffect(() => {
        if (formGhatFrom != '') {
            generateGhatToOptions(modalRoutes, formGhatFrom, setFormGhatToOptions, setFormGhatTo);
        }
    }, [formGhatFrom])

    useEffect(() => {
        const [depH = '', depM = '', depS = ''] = tripSettings?.departureTime?.split(':') || [];
        const [durH = '', durM = '', durS = ''] = tripSettings?.tripDuration?.split(':') || [];

        setTrip(tripSettings?.id || '');
        setFormGhatFrom(tripSettings?.route?.ghatFrom?.id || formGhatFrom);
        setFormGhatTo(tripSettings?.route?.ghatTo?.id || formGhatTo);
        setServiceType(tripSettings?.serviceType || serviceType);
        setVessel(tripSettings?.vessel?.id || vessel);
        setDepartureHour(depH);
        setDepartureMin(depM);
        setDepartureSec(depS);
        setTripDurationHour(durH);
        setTripDurationMin(durM);
        setTripDurationSec(durS);
        setTripStatus(tripSettings?.status || tripStatus);
        setDockNo(tripSettings?.dockNo || '');
        setBookingMode(tripSettings?.bookingMode || bookingMode);
        setActivateBefore(tripSettings?.activateBefore || '');
        setOnlineSeat(tripSettings?.onlineSeat || '');
        setOfflineSeat(tripSettings?.offlineSeat || '');
        setSelectedMonths(tripSettings?.months?.reduce((months: SelectedMonthsMap, month: string) => {
            months[month] = true;
            return months;
          }, {} as SelectedMonthsMap))
    }, [tripSettings]);


    const handleTripSaveClick = async () => {

        const tripRoute = modalRoutes.find((route: Route) => route.ghatFrom.id == formGhatFrom && route.ghatTo.id == formGhatTo);
        const departureTime = (departureHour == '' || departureMin == '' || departureSec == '') ? '' : `${departureHour}:${departureMin}:${departureSec}`;
        const tripDuration = (tripDurationHour == '' || tripDurationMin == '' || tripDurationSec == '') ? '' : `${tripDurationHour}:${tripDurationMin}:${tripDurationSec}`;
        const tripMonths = Object.keys(selectedMonths).filter(month => selectedMonths[month]);

        const postData: TripSettingsPost = {
            id: tripSettings?.id || null,
            route: {
                id: tripRoute?.id || null,
            },
            vessel: {
                id: vessel || null,
            },
            months: tripMonths,
            serviceType: serviceType,
            onlineSeat: Number(onlineSeat),
            offlineSeat: Number(offlineSeat),
            dockNo: dockNo,
            bookingMode: bookingMode,
            departureTime: departureTime,
            tripDuration: tripDuration,
            activateBefore: activateBefore,
            status: tripStatus,
        }

        const saved = await saveTripSettings(postData);

        if (saved) {
            fetchTripSettings(
                modalRoutes,
                ghatFrom,
                ghatTo,
                setTripSettingsTableRows,
                setTripList
            );
        }
    }

    return (
        <ModalWrapper
            open={tripModalOpen}
            handleClose={handleTripModalClose}
            title={'Trip Settings'}>
            <div>
                <form>
                    <div className='grid grid-cols-3 gap-x-4 gap-y-2'>
                        <input type="text" value={trip} readOnly hidden />
                        {
                            (!tripSettings.id || tripSettings.id == '') &&
                            <div>
                                <FormControl
                                    mandatoryField={true}
                                    label='Division'
                                    type={'select'}
                                    id={'formDivision'}
                                    value={formDivision}
                                    onChange={handleInputChange(setFormDivision)}
                                    options={divisionOptions}
                                />
                            </div>
                        }
                        <div>
                            <FormControl
                                mandatoryField={true}
                                label='From'
                                type={'select'}
                                id={'formGhatFrom'}
                                value={formGhatFrom}
                                onChange={handleInputChange(setFormGhatFrom)}
                                options={formGhatFromOptions}
                            />
                        </div>
                        <div>
                            <FormControl
                                mandatoryField={true}
                                label='To'
                                type={'select'}
                                id={'formGhatTo'}
                                value={formGhatTo}
                                onChange={handleInputChange(setFormGhatTo)}
                                options={formGhatToOptions}
                            />
                        </div>
                        <div>
                            <FormControl
                                mandatoryField={true}
                                label='Service Type'
                                type={'select'}
                                id={'serviceType'}
                                value={serviceType}
                                onChange={handleInputChange(setServiceType)}
                                options={serviceTypeOptions}
                            />
                        </div>
                        <div>
                            <FormControl
                                mandatoryField={true}
                                label='Vessel'
                                type={'select'}
                                id={'formVessel'}
                                value={vessel}
                                options={vesselOptions}
                                onChange={handleInputChange(setVessel)}
                            />
                        </div>
                        <div>
                            <label
                                className='block mb-2 text-sm font-medium text-gray-900'
                                htmlFor='departureHour'
                            >
                                Departure Time
                            </label>
                            <div className='flex'>
                                <div className='w-1/3 pr-2'>
                                    <FormControl
                                        mandatoryField={true}
                                        type={'number'}
                                        id={'departureHour'}
                                        placeholder='HH'
                                        value={departureHour}
                                        onChange={handleInputChange(setDepartureHour)}
                                    />
                                </div>
                                <div className='w-1/3 pr-2'>
                                    <FormControl
                                        mandatoryField={true}
                                        type={'number'}
                                        id={'departureMin'}
                                        placeholder='MM'
                                        value={departureMin}
                                        onChange={handleInputChange(setDepartureMin)}
                                    />
                                </div>
                                <div className='w-1/3'>
                                    <FormControl
                                        mandatoryField={true}
                                        type={'number'}
                                        id={'departureSec'}
                                        placeholder='SS'
                                        value={departureSec}
                                        onChange={handleInputChange(setDepartureSec)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <label
                                className='block mb-2 text-sm font-medium text-gray-900'
                                htmlFor='durationHour'
                            >
                                Duration
                            </label>
                            <div className='flex'>
                                <div className='w-1/3 pr-2'>
                                    <FormControl
                                        mandatoryField={true}
                                        type={'number'}
                                        id={'durationHour'}
                                        placeholder='HH'
                                        value={tripDurationHour}
                                        onChange={handleInputChange(setTripDurationHour)}
                                    />
                                </div>
                                <div className='w-1/3 pr-2'>
                                    <FormControl
                                        mandatoryField={true}
                                        type={'number'}
                                        id={'durationMin'}
                                        placeholder='MM'
                                        value={tripDurationMin}
                                        onChange={handleInputChange(setTripDurationMin)}
                                    />
                                </div>
                                <div className='w-1/3'>
                                    <FormControl
                                        mandatoryField={true}
                                        type={'number'}
                                        id={'durationSec'}
                                        placeholder='SS'
                                        value={tripDurationSec}
                                        onChange={handleInputChange(setTripDurationSec)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div>
                            <FormControl
                                mandatoryField={true}
                                label='Status'
                                type={'select'}
                                id={'tripStatus'}
                                value={tripStatus}
                                options={statusOptions}
                                onChange={handleInputChange(setTripStatus)}
                            />
                        </div>
                        <div>
                            <FormControl
                                mandatoryField={true}
                                label='Dock No'
                                type={'text'}
                                id={'dockNo'}
                                value={dockNo}
                                onChange={handleInputChange(setDockNo)}
                            />
                        </div>
                        <div>
                            <FormControl
                                mandatoryField={true}
                                label='Booking Mode'
                                type={'select'}
                                id={'bookingMode'}
                                value={bookingMode}
                                options={bookingModeOptions}
                                onChange={handleInputChange(setBookingMode)}
                            />
                        </div>
                        <div>
                            <FormControl
                                mandatoryField={true}
                                label='Online Seat'
                                type={'number'}
                                id={'onlineSeat'}
                                value={onlineSeat}
                                onChange={handleInputChange(setOnlineSeat)}
                            />
                        </div>
                        <div>
                            <FormControl
                                mandatoryField={true}
                                label='Offline Seat'
                                type={'number'}
                                id={'offlineSeat'}
                                value={offlineSeat}
                                onChange={handleInputChange(setOfflineSeat)}
                            />
                        </div>
                        <div>
                            <FormControl
                                mandatoryField={true}
                                label='Activate Before (hours)'
                                type={'number'}
                                id={'activateBefore'}
                                value={activateBefore}
                                onChange={handleInputChange(setActivateBefore)}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="monthSelect" className='text-sm font-medium text-gray-900'>Trip Months <span className='text-red-500'>*</span></label>
                        <div id='monthSelect' className='flex gap-3 mt-2'>
                            {monthOptions.map(option => (
                                <div key={option.value}>
                                    <label className=
                                        {`block w-14 cursor-pointer text-center py-2 duration-300 rounded-full 
                                            ${selectedMonths[option.value] || false ?
                                                `bg-sky-400 text-white`
                                                :
                                                `bg-neutral-200 text-neutral-400`
                                            }`}
                                    >
                                        <input
                                            hidden
                                            type="checkbox"
                                            checked={selectedMonths[option.value] || false}
                                            onChange={() => handleCheckboxChange(option.value, setSelectedMonths)}
                                        />
                                        {option.label}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                </form>
                <div className='w-full mt-4'>
                    <DefaultButton
                        onClick={handleTripSaveClick}
                        buttonText={'Save Trip Details'}
                    />
                </div>
            </div>
        </ModalWrapper>
    )
}

export default TripSettingsModal