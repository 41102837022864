import React from 'react'
import { Trip } from '../../../manage/trip-management/trip-interfaces';
import { dateUtil } from '../../../../utility/dateUtil';

interface BookingTripDetailsProps {
    trip: Trip
}

const BookingTripDetails: React.FC<BookingTripDetailsProps> = ({ trip }) => {
    return (
        <div className="flex flex-col justify-between h-full">
            <h4 className='m-0 mb-2 text-sky-500'>
                Trip Details
            </h4>
            <div>
                <div className="flex">
                    <div className='my-1 w-1/2 px-2'>
                        <div className='text-sm text-neutral-500 font-semibold'>Date</div>
                        <div className='font-semibold text-neutral-600'>{trip.tripDate !== '' && dateUtil.formatDateWithDay(trip.tripDate)}</div>
                    </div>
                    <div className='my-1 w-1/2 px-2'>
                        <div className='text-sm text-neutral-500 font-semibold'>Departure</div>
                        <div className='font-semibold text-neutral-600'>{trip.departureTime !== '' && dateUtil.formatTime(trip.departureTime)}</div>
                    </div>
                </div>
                <hr />
                <div className="flex">
                    <div className='my-1 w-1/2 px-2'>
                        <div className='text-sm text-neutral-500 font-semibold'>Source</div>
                        <div className='font-semibold text-neutral-600'>{trip.route.ghatFrom.ghatName}</div>
                    </div>
                    <div className='my-1 w-1/2 px-2'>
                        <div className='text-sm text-neutral-500 font-semibold'>Destination</div>
                        <div className='font-semibold text-neutral-600'>{trip.route.ghatTo.ghatName}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BookingTripDetails;