import toast from "react-hot-toast"
import { useNavigate } from "react-router-dom"
import API_ENDPOINT from "../../../constants/api-constants"
import { ROUTES } from "../../../constants/routes-constants"
import { SelectOption } from "../../../constants/type-constants"
import { useApiService } from "../../../hooks/useApiService"
import { useHandleErrorService } from "../../../hooks/useHandleErrorService"
import { LayoutPost, VesselLayoutSlot } from "./vessel-layout-interfaces"

const itemBorder: { [key: string]: string } = {
    'Female': 'border-pink-500',
    'PWD': 'border-blue-500',
    'Senior Citizen': 'border-yellow-500',
    'Unreserved': 'border-gray-500',
    'Goods': 'border-green-500',
    'Bicycle': 'border-teal-500',
    'Two Wheeler': 'border-purple-500',
    'Four Wheeler': 'border-red-500',
};

export const useVesselLayoutService = () => {
    const navigate = useNavigate();

    const {
        fetchAuthRequest,
        postAuthRequest
    } = useApiService();
    const { handleError } = useHandleErrorService();

    const handleBackButtonClick = (divisionId: string) => {
        navigate(ROUTES.MANAGE.VESSEL, { state: { divisionId: divisionId } })
    }

    const handleNumberInputChange = (updateState: React.Dispatch<React.SetStateAction<number>>) =>
        (event: React.ChangeEvent<HTMLSelectElement>) => {
            const value = Number(event.target.value);
            updateState(value);
        };

    const handleInputChange = (updateState: React.Dispatch<React.SetStateAction<string>>) =>
        (event: React.ChangeEvent<HTMLSelectElement>) => {
            updateState(event.target.value);
        };

    const getItemBorderClass = (itemName: string | undefined) => {
        if (itemName) {
            return itemBorder[itemName] || 'border-neutral-300';
        }
        return 'border-neutral-300';
    };

    const handleGenerateLayoutButtonClick = async (
        rowInput: number,
        colInput: number,
        deckInput: string,
        setRows: React.Dispatch<React.SetStateAction<number>>,
        setColumns: React.Dispatch<React.SetStateAction<number>>,
        setDeck: React.Dispatch<React.SetStateAction<string>>
    ) => {
        setRows(rowInput);
        setColumns(colInput);
        setDeck(deckInput);
    }

    const fetchVesselLayout = async (
        vesselId: string,
        deckId: string,
        setRows: React.Dispatch<React.SetStateAction<number>>,
        setRowInput: React.Dispatch<React.SetStateAction<number>>,
        setColumns: React.Dispatch<React.SetStateAction<number>>,
        setColInput: React.Dispatch<React.SetStateAction<number>>,
        setVesselLayoutSlots: React.Dispatch<React.SetStateAction<VesselLayoutSlot[]>>
    ) => {
        try {
            const response = await fetchAuthRequest(API_ENDPOINT.MANAGE.VESSEL_LAYOUT.GET_VESSEL_LAYOUT,
                {
                    vesselId: vesselId,
                    deckId: deckId
                }
            );
            const { vesselLayoutSlots } = response.data.object;
            const processedData: VesselLayoutSlot[] = []

            let maxRows = 0;
            let maxCols = 0;

            if (vesselLayoutSlots.length > 0) {
                vesselLayoutSlots.forEach((slot: any) => {
                    maxRows = slot.rowNo > maxRows ? slot.rowNo : maxRows;
                    maxCols = slot.columnNo > maxCols ? slot.columnNo : maxCols;

                    processedData.push({
                        rowNo: slot.rowNo,
                        columnNo: slot.columnNo,
                        seatNo: slot.seatNo,
                        borderColor: getItemBorderClass(slot.item.itemType),
                        item: {
                            id: slot.item.id,
                            itemName: slot.item.itemType,
                        }
                    });
                });

                maxRows = maxRows + 1;
                maxCols = maxCols + 1;
            }

            setRows(maxRows);
            setRowInput(maxRows);
            setColumns(maxCols);
            setColInput(maxCols);
            setVesselLayoutSlots(processedData);
        } catch (error) {
            handleError(error);
        }
    }

    const handleLayoutInputChange = (
        itemOptions: SelectOption[],
        vesselLayoutSlots: VesselLayoutSlot[],
        setVesselLayoutSlots: React.Dispatch<React.SetStateAction<VesselLayoutSlot[]>>
    ) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const [_, row, col] = name.split('_');
        const rowNo = Number(row);
        const colNo = Number(col);

        if (isNaN(rowNo) || isNaN(colNo)) {
            console.error(`Invalid row or column number: ${rowNo}, ${colNo}`);
            return;
        }

        const existingSlotIndex = vesselLayoutSlots.findIndex(slot => slot.rowNo == rowNo && slot.columnNo == colNo);
        const updatedList = [...vesselLayoutSlots];

        if (existingSlotIndex != -1) {
            if (value == '') {
                updatedList[existingSlotIndex].borderColor = getItemBorderClass('default');
                updatedList.splice(existingSlotIndex, 1);
            } else {
                updatedList[existingSlotIndex].borderColor = getItemBorderClass(updatedList[existingSlotIndex].item.itemName);
                updatedList[existingSlotIndex].seatNo = value;
            }
            setVesselLayoutSlots(updatedList);
        } else {
            const newSeat = {
                rowNo: rowNo,
                columnNo: colNo,
                seatNo: value,
                borderColor: getItemBorderClass(itemOptions[0]?.label),
                item: {
                    id: itemOptions[0]?.value,
                    itemName: itemOptions[0]?.label,
                }
            };
            updatedList.push(newSeat);
            setVesselLayoutSlots(updatedList);
        }
    }

    const handleSlotItemChange = (
        itemOptions: SelectOption[],
        vesselLayoutSlots: VesselLayoutSlot[],
        setVesselLayoutSlots: React.Dispatch<React.SetStateAction<VesselLayoutSlot[]>>
    ) => (e: React.ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        const item = itemOptions.find(option => option.value == value);
        const [_, row, col] = name.split('_');
        const rowNo = Number(row);
        const colNo = Number(col);

        const slotIndex = vesselLayoutSlots.findIndex(seat => seat.rowNo == rowNo && seat.columnNo == colNo);
        if (slotIndex != -1) {
            const updatedList = [...vesselLayoutSlots];
            if (item) {
                updatedList[slotIndex].item = {
                    id: item.value,
                    itemName: item.label
                };
                updatedList[slotIndex].borderColor = getItemBorderClass(item.label || 'default');
            }
            setVesselLayoutSlots(updatedList);
        }
    }

    const saveVesselLayout = async (postData: LayoutPost) => {
        try {
            const response = await postAuthRequest(API_ENDPOINT.MANAGE.VESSEL_LAYOUT.SAVE_VESSEL_LAYOUT, postData);

            const { message } = response.data;

            toast.success(message);
        } catch (error) {
            handleError(error);
        }
    }

    return {
        handleBackButtonClick,
        handleNumberInputChange,
        handleInputChange,
        getItemBorderClass,
        fetchVesselLayout,
        handleGenerateLayoutButtonClick,

        handleLayoutInputChange,
        handleSlotItemChange,
        saveVesselLayout

    }
}