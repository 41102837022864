import SwapHorizRoundedIcon from '@mui/icons-material/SwapHorizRounded';
import React, { useRef, useState } from 'react';
import Body from '../../../components/body/Body';

import { useNavigate } from 'react-router-dom';
import SearchInput from '../../../components/forms/SearchInput';
import SearchOptionModal from '../../../components/modals/SearchOptionModal';
import { useHandleErrorService } from '../../../hooks/useHandleErrorService';

const BundleTicketBooking: React.FC = () => {

    const navigate = useNavigate();
    const { handleError } = useHandleErrorService();

    const [origin, setOrigin] = useState('');
    const [originName, setOriginName] = useState('Origin');
    const [destination, setDestination] = useState('');
    const [destinationName, setDestinationName] = useState('Destination');
    const [searchText, setSearchText] = useState('');
    const [packageType, setPackageType] = useState('');
    const [packageTypeText, setPackageTypeText] = useState('');

    const searchInputRef = useRef<HTMLInputElement>(null);

    const [selectedParameter, setSelectedParameter] = useState('');



    const ghatList = [
        {
            divisionId: '1',
            divisionName: 'A',
            ghats: [
                { id: '1', name: 'Ghat A1' },
                { id: '2', name: 'Ghat A2' },
                { id: '3', name: 'Ghat A3' },
                { id: '4', name: 'Ghat A4' },
            ]
        },
        {
            divisionId: '2',
            divisionName: 'B',
            ghats: [
                { id: '5', name: 'Ghat B1' },
                { id: '6', name: 'Ghat B2' },
                { id: '7', name: 'Ghat B3' },
                { id: '8', name: 'Ghat B4' },
            ]
        }
    ]

    const handleSwapClick = () => {
        const newOrigin = destination;
        const newDestination = origin;
        const newOriginName = destinationName;
        const newDestinationName = originName;

        setOrigin(newOrigin);
        setOriginName(newOriginName);
        setDestination(newDestination);
        setDestinationName(newDestinationName);
    }

    const handleSearchClick = () => {
        navigate("/search-results");
        return null;
    }

    const searchOptionDialog = useRef<HTMLDialogElement>(null);

    const showSearchModal = (clickedDiv: string) => {
        setSelectedParameter(clickedDiv);
        searchOptionDialog.current?.showModal();
        searchInputRef.current?.focus();
    }

    const handleSearchOptionSelect = (ghatId: any, ghatName: string, parent: string) => {
        switch (parent) {
            case 'origin':
                setOrigin(ghatId);
                setOriginName(ghatName);
                break;
            case 'destination':
                setDestination(ghatId);
                setDestinationName(ghatName);
                break;
            default:
                break;
        }

        searchOptionDialog.current?.close();
        setSelectedParameter('');
        setSearchText('');
    }

    const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchText(e.target.value);
    };

    const matchesSearchText = (ghatName: string) => {
        if (searchText.trim() !== '') {
            return ghatName.toLowerCase().includes(searchText.toLowerCase());
        }

        return true;
    }
    return (
        <Body>
            <div>
                <div className='w-1/3 mb-4'>
                    <h4>Route Ticket Bundle</h4>
                    <h6>Book Tickets in Bulk to Receive Discounts.</h6>
                </div>

                <div className="flex flex-wrap justify-center">
                    <div className="rounded-xl w-full md:w-3/4 lg:w-2/3 outline outline-2 outline-blue-400 flex flex-wrap bg-slate-50">
                        <div className="w-full lg:w-7/12 flex flex-wrap">
                            <div className="w-full lg:w-1/2 px-6 bg-transparent">
                                <div
                                    className="w-full md:text-center text-nowrap text-clip overflow-x-hidden py-3.5"
                                    onClick={() => showSearchModal('origin')}>
                                    {originName}
                                </div>
                            </div>
                            <div className="relative bg-slate-400 flex items-center">
                                <button className="absolute -left-12 top-7 lg:-left-5 lg:top-1.5 flex p-2 bg-zinc-500 text-white rounded-full scale-90 rotate-90 md:rotate-0"
                                    onClick={handleSwapClick}
                                >{<SwapHorizRoundedIcon />}</button>
                            </div>
                            <div className="w-full lg:w-1/2 px-6 bg-transparent">
                                <div
                                    className="w-full md:text-center text-nowrap text-clip overflow-x-hidden py-3.5"
                                    onClick={() => showSearchModal('destination')}>
                                    {destinationName}
                                </div>
                            </div>
                        </div>
                        <div className="w-full lg:w-5/12 flex flex-wrap">
                            <div className="w-full lg:w-7/12 px-6 bg-transparent">
                                <input
                                    className="w-full md:text-center text-nowrap text-clip overflow-x-hidden py-3.5 bg-transparent"
                                    type="number"
                                    placeholder="No. of Tickets"
                                />
                            </div>
                            <div className="w-full lg:w-5/12">
                                <button
                                    className="w-full justify-center h-full p-3.5 bg-sky-600 text-white font-medium rounded-b-xl lg:rounded-bl-none lg:rounded-r-xl flex gap-2 pl-5 items-center outline outline-1 outline-sky-600"
                                    onClick={handleSearchClick}>
                                    Buy Tickets
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <SearchOptionModal
                ref={searchOptionDialog}
                title={"Select Ghat"}
            >
                <div className="flex flex-col items-center justify-center">
                    <SearchInput
                        id={"ghatInput"}
                        onChange={handleSearchInputChange}
                        value={searchText}
                        inputRef={searchInputRef}
                    />
                    <div className="mt-2 w-full lg:h-80 lg:overflow-y-scroll">
                        {
                            ghatList.map((division => (
                                <div key={division.divisionId}>
                                    <div className="my-1 w-full px-4 py-1 text-sm bg-slate-200">
                                        {division.divisionName}
                                    </div>
                                    {division.ghats.map((ghat => (
                                        (matchesSearchText(ghat.name) &&
                                            <div key={ghat.id}
                                                className="bg-slate-100 border border-1 border-slate-200 my-1 mx-2 px-4 py-1 text-sm hover:cursor-pointer"
                                                onClick={() => handleSearchOptionSelect(`${ghat.id}`, `${ghat.name}`, selectedParameter)}
                                            >
                                                {ghat.name}
                                            </div>)
                                    )))}
                                </div>
                            )))
                        }
                    </div>
                </div>
            </SearchOptionModal>
        </Body>
    )
}

export default BundleTicketBooking