import { dateUtil } from "../../../utility/dateUtil"

export interface IOrigin {
    originId: string
    originName: string
}


export interface IDestination {
    destinationId: string
    destinationName: string
}

export interface ISearchParams {
    origin: IOrigin
    destination: IDestination
    tripDate: string
}

export const InitialSearchParamState: ISearchParams = {
    origin: {
        originId: '',
        originName: 'Origin'
    },
    destination: {
        destinationId: '',
        destinationName: 'Destination'
    },
    tripDate: dateUtil.formatDate((new Date()).toString())
}

export interface IDivision {
    id: string;
    divisionName: string;
}

export interface IGhatDetail {
    id: string;
    ghatName: string;
    division: IDivision;
}

export interface IGhatOption {
    divisionId: string
    divisionName: string
    ghats: {
        id: string
        name: string
    }[]
}