import React, { ChangeEvent, ReactNode, useEffect, useRef, useState } from 'react'
import Body from '../../../components/body/Body';
import Card from '../../../components/cards/Card';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import DirectionsBoatFilledRoundedIcon from '@mui/icons-material/DirectionsBoatFilledRounded';
import AnchorRoundedIcon from '@mui/icons-material/AnchorRounded';
import AccessTimeFilledRoundedIcon from '@mui/icons-material/AccessTimeFilledRounded';
import HourglassTopRoundedIcon from '@mui/icons-material/HourglassTopRounded';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import BorderColorRoundedIcon from '@mui/icons-material/BorderColorRounded';
import TabWrapper from '../../../components/tabs/TabWrapper';
import PassengerTab from './PassengerTab';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import Table from '../../../components/tables/Table';

const TicketManage: React.FC = () => {
    const [passengerName, setPassengerName] = useState('');
    const [passengerAge, setPassengerAge] = useState('');
    const [passengerGender, setPassengerGender] = useState('');
    const [passengerIsPwd, setPassengerIsPwd] = useState('');

    const [passengerList, setPassengerList] = useState<any[]>([]);
    const [vehicleList, setVehicleList] = useState<any[]>([]);

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value;
        switch (e.target.name) {
            case 'passengerName':
                setPassengerName(val);
                break;
            case 'passengerAge':
                setPassengerAge(val);
                break;
            case 'passengerGender':
                setPassengerGender(val);
                break;
            case 'passengerIsPwd':
                setPassengerIsPwd(val);
                break;
            default:
                break;
        }
    }

    const VehicleTab: React.FC = () => {
        return (
            <div>
                <h1>Vehicle Tab</h1>
            </div>
        );
    }

    const LuggageTab: React.FC = () => {
        return (
            <div>
                <h1>Luggage Tab</h1>
            </div>
        );
    }

    const handleAddPassengerButtonClick = () => {
        const newPassenger = {
            passengerName: passengerName,
            passengerAge: passengerAge,
            passengerGender: passengerGender,
            passengerIsPwd: passengerIsPwd,
        }

        setPassengerList((prevList) => [...prevList, newPassenger]);

        setPassengerName('');
        setPassengerAge('');
        setPassengerGender('');
        setPassengerIsPwd('');
    }

    const handleDeleteButtonClick = (type: string, index: number) => {
        console.log(index);

        switch (type) {
            case 'passenger':
                {
                    const newList = passengerList.slice(0, index).concat(passengerList.slice(index + 1));
                    setPassengerList(newList);
                    break;
                }
            default:
                break;
        }
    }

    return (
        <Body>
            <div className='flex'>
                <div className='w-1/3 px-4 '>
                    <div className='text-sm mb-1'>Route</div>
                    <div className='flex items-center gap-4'>
                        <div>
                            <ArrowUpwardRoundedIcon className='text-neutral-500' />
                        </div>
                        <div>
                            <div className='font-medium mb-1'>Madhyamkanda</div>
                            <div className='font-medium mb-1'>Guwahati</div>
                        </div>
                    </div>
                </div>
                <div className='w-1/3 px-4 '>
                    <div className='text-sm mb-1'>Vessel</div>
                    <div className='flex items-center'>
                        <div>
                            <div className='font-medium mb-1'><DirectionsBoatFilledRoundedIcon className='mr-3 text-neutral-500' fontSize='small' /> MV Charaidew</div>
                            <div className='font-medium mb-1'><AnchorRoundedIcon className='mr-3 text-neutral-500' fontSize='small' /> Dock: 3</div>
                        </div>
                    </div>
                </div>
                <div className='w-1/3 px-4 '>
                    <div className='text-sm mb-1'>Timings</div>
                    <div className='flex items-center'>
                        <div>
                            <div className='font-medium mb-1'><AccessTimeFilledRoundedIcon className='mr-3 text-neutral-500' fontSize='small' /> 09:30 AM</div>
                            <div className='font-medium mb-1'><HourglassTopRoundedIcon className='mr-3 text-neutral-500' fontSize='small' /> 01:00 hours</div>
                        </div>
                    </div>
                </div>
            </div>
            <Card>
                <div className='flex flex-wrap'>
                    <div className='w-1/2 border-r'>
                        <TabWrapper
                            itemList={[
                                {
                                    label: 'Passenger',
                                    content:
                                        <PassengerTab
                                            key={'passenger'}
                                            passengerName={passengerName}
                                            passengerAge={passengerAge}
                                            passengerGender={passengerGender}
                                            passengerIsPwd={passengerIsPwd}
                                            handleInputChange={handleInputChange}
                                            handleAddButtonClick={handleAddPassengerButtonClick}
                                        />
                                },
                                { label: 'Vehicle', content: <VehicleTab key={'vehicle'} /> },
                                { label: 'Luggage', content: <LuggageTab key={'luggage'} /> },
                            ]}
                        />
                    </div>
                    <div className='w-1/2'>
                        <div className='border-b px-6 py-3'>
                            <div className='font-medium'>Ticket Details</div>
                        </div>
                        <div className='px-6 py-3'>
                            {

                                passengerList.length ?
                                    <>
                                        <div className='mb-1 font-semibold'>Passenger Details:</div>
                                        <Table
                                            bordered={false}
                                            theadRows={["Name", "Age", "Gender", "PwD", ""]}
                                            tbodyRows={passengerList.map((passenger, index) => [
                                                passenger.passengerName,
                                                passenger.passengerAge,
                                                passenger.passengerGender,
                                                passenger.passengerIsPwd,
                                                <div key={passenger.name} className='flex gap-4 justify-end'>
                                                    <button className='text-sky-500'
                                                        onClick={undefined}
                                                    >
                                                        <BorderColorRoundedIcon />
                                                    </button>

                                                    <button className='text-red-400'
                                                        onClick={() => handleDeleteButtonClick('passenger', index)}
                                                    >
                                                        <CancelRoundedIcon />
                                                    </button>
                                                </div>
                                            ])}
                                        >
                                        </Table>
                                        <hr className='my-2' />
                                    </>
                                    : ''
                            }
                            {/* {

                                passengerList.length ?
                                    <>
                                        <div className='mb-1 font-semibold'>Passenger Details:</div>
                                        <Table
                                            bordered={false}
                                            theadRows={["Name", "Age", "Gender", "PwD", ""]}
                                            tbodyRows={passengerList.map(passenger => [
                                                passenger.passengerName,
                                                passenger.passengerAge,
                                                passenger.passengerGender,
                                                passenger.passengerIsPwd,
                                                <div key={passenger.name} className='flex gap-4 justify-end'>
                                                    <button className='text-sky-500'
                                                        onClick={undefined}
                                                    >
                                                        <BorderColorRoundedIcon />
                                                    </button>

                                                    <button className='text-red-400'
                                                        onClick={undefined}
                                                    >
                                                        <CancelRoundedIcon />
                                                    </button>
                                                </div>
                                            ])}
                                        >
                                        </Table>
                                    </>
                                    : ''
                            } */}
                        </div>
                    </div>
                </div>
            </Card>
        </Body >
    )
}

export default TicketManage