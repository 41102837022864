import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import React, { useEffect, useState } from 'react';
import Body from '../../../components/body/Body';
import DefaultButton from '../../../components/buttons/DefaultButton';
import SecondaryButton from '../../../components/buttons/SecondaryButton';
import Card from '../../../components/cards/Card';
import FormControl from '../../../components/forms/FormControl';
import { SelectOption } from '../../../constants/type-constants';
import { useCommonService } from '../../../hooks/useCommonService';
import { Route } from '../route-management/route-interfaces';
import { SelectedMonthsMap, TripSettingsTableRow, TripSettingsView } from './trip-settings-interfaces';
import TripSettingsModal from './TripSettingsModal';
import TripSettingsTable from './TripSettingsTable';
import { useTripSettingsService } from './useTripSettingsService';

const TripSettings: React.FC = () => {
    const {
        fetchDivisionOptions,
        fetchRoutesByDivision,
        fetchMonthOptions
    } = useCommonService();

    const {
        handleInputChange,
        generateGhatFromOptions,
        generateGhatToOptions,
        fetchTripSettings,
    } = useTripSettingsService();

    const [tripSettingsTableRows, setTripSettingsTableRows] = useState<TripSettingsTableRow[]>([]);
    const [tripSettingsList, setTripSettingsList] = useState<TripSettingsView[]>([]);
    const [tripSettings, setTripSettings] = useState<any>({});

    const [divisionOptions, setDivisionOptions] = useState<SelectOption[]>([]);
    const [ghatFromOptions, setGhatFromOptions] = useState<SelectOption[]>([]);
    const [ghatToOptions, setGhatToOptions] = useState<SelectOption[]>([]);
    const [monthOptions, setMonthOptions] = useState<SelectOption[]>([]);
    const [selectedMonths, setSelectedMonths] = useState<SelectedMonthsMap>({});

    const [routes, setRoutes] = useState<Route[]>([]);

    const [division, setDivision] = useState<string>('');
    const [ghatFrom, setGhatFrom] = useState<string>('');
    const [ghatTo, setGhatTo] = useState<string>('');


    const [tripModalOpen, setTripModalOpen] = useState(false);
    const handleTripModalOpen = () => setTripModalOpen(true);
    const handleTripModalClose = () => setTripModalOpen(false);

    useEffect(() => {
        fetchDivisionOptions(setDivisionOptions, setDivision);
        fetchMonthOptions(setMonthOptions, setSelectedMonths);
    }, []);

    useEffect(() => {
        if (division && division !== '') {
            fetchRoutesByDivision(division, setRoutes);
        }
    }, [division]);

    useEffect(() => {
        if (routes.length == 0) {
            setGhatFromOptions([]);
            setGhatToOptions([]);
        } else {
            generateGhatFromOptions(routes, setGhatFromOptions, setGhatFrom);
        }
    }, [routes]);

    useEffect(() => {
        if (ghatFrom != '') {
            generateGhatToOptions(routes, ghatFrom, setGhatToOptions, setGhatTo);
        }
    }, [ghatFrom]);

    const handleCreateSettingsClick = async () => {
        setTripSettings({});
        handleTripModalOpen();
    }

    const handleSettingsEditClick = (id: string) => {
        generateGhatFromOptions(routes, setGhatFromOptions, setGhatFrom);
        const tripData = tripSettingsList.find(trip => trip.id == id);

        console.log(tripData);
        
        if (tripData) {
            setTripSettings(tripData);
            handleTripModalOpen();
        }
    }

    const handleFetchSettings = async () => fetchTripSettings(
        routes,
        ghatFrom,
        ghatTo,
        setTripSettingsTableRows,
        setTripSettingsList
    );

    return (
        <Body>
            <div className='flex flex-wrap justify-between items-end'>
                <h3 className='flex justify-center items-center gap-4 mb-2'><SettingsRoundedIcon /> Trip Settings</h3>
            </div>
            <Card>
                <div>
                    <div className='flex justify-between'>
                        <div>

                        </div>
                    </div>
                    <div className='flex gap-4 items-end'>
                        <div className='w-8/12 flex gap-4 items-end'>
                            <div className='w-1/3'>
                                <FormControl
                                    label='Division'
                                    type={'select'}
                                    id={'division'}
                                    value={division}
                                    options={divisionOptions}
                                    onChange={handleInputChange(setDivision)}
                                />
                            </div>
                            <div className='w-1/3'>
                                <FormControl
                                    label='From'
                                    type={'select'}
                                    id={'ghatFrom'}
                                    value={ghatFrom}
                                    options={ghatFromOptions}
                                    onChange={handleInputChange(setGhatFrom)}
                                />
                            </div>
                            <div className='w-1/3'>
                                <FormControl
                                    label='To'
                                    type={'select'}
                                    id={'ghatTo'}
                                    value={ghatTo}
                                    options={ghatToOptions}
                                    onChange={handleInputChange(setGhatTo)}
                                />
                            </div>
                        </div>
                        <div className='flex w-4/12 gap-4'>
                            <div className='w-7/12'>
                                <DefaultButton
                                    onClick={handleFetchSettings}
                                    buttonText={'Fetch Trip Settings'}
                                />
                            </div>
                            <div className='w-5/12'>
                                <SecondaryButton
                                    onClick={handleCreateSettingsClick}
                                    buttonText={'Create New'}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Card>

            <TripSettingsTable
                tripSettingsTableRows={tripSettingsTableRows}
                handleSettingsEditClick={handleSettingsEditClick}
            />

            <TripSettingsModal
                tripSettings={tripSettings}
                routes={routes}
                division={division}
                divisionOptions={divisionOptions}
                tripModalOpen={tripModalOpen}
                handleTripModalOpen={handleTripModalOpen}
                handleTripModalClose={handleTripModalClose}
                ghatFrom={ghatFrom}
                ghatTo={ghatTo}
                setTripSettingsTableRows={setTripSettingsTableRows}
                setTripList={setTripSettingsList}
                monthOptions={monthOptions}
                selectedMonths={selectedMonths}
                setSelectedMonths={setSelectedMonths} />
        </Body>
    )
}

export default TripSettings