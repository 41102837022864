import React, { ReactNode } from 'react';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';

interface ModalWrapperProps {
    title: string
    open: boolean;
    handleClose: () => void;
    width?: string
    children: ReactNode;
}

const widthMapping: Record<string, DialogProps['maxWidth']> = {
    'small': 'xs',
    'medium': 'md',
    'large': 'lg',
};

const ModalWrapper: React.FC<ModalWrapperProps> = ({ open, handleClose, children, width, title }) => {
    const mappedWidth: DialogProps['maxWidth'] = widthMapping[width || 'medium'];

    return (
        <Dialog
            fullWidth={true}
            maxWidth={mappedWidth}
            open={open}
            onClose={handleClose}
            scroll="paper"
            PaperProps={{
                style: {
                    borderRadius: 12,
                }
            }}
            disableScrollLock={true}
            // style={{ marginTop: '5rem' }}
        >
            <DialogTitle
                fontFamily={"'Montserrat', 'sans-serif'"}
                className='bg-sky-50 flex w-full justify-between items-center border-b border-b-sky-300'
            >
                {title} <button className='bg-transparent' onClick={handleClose}><ClearRoundedIcon /></button>
            </DialogTitle>
            <DialogContent className='mt-4'>
                {children}
            </DialogContent>
        </Dialog>
    );
}

export default ModalWrapper;