import { AxiosResponse } from "axios";
import toast from "react-hot-toast";
import API_ENDPOINT from "../../../constants/api-constants";
import { useApiService } from "../../../hooks/useApiService";
import { useHandleErrorService } from "../../../hooks/useHandleErrorService";
import { Division, DivisionPost, DivisionTableRows } from "./division-interfaces";
import Swal from "sweetalert2";
import swalConfig from "../../../config/swalConfig";

export const useDivisionManagementService = () => {
    const {
        fetchAuthRequest,
        postAuthRequest,
    } = useApiService()

    const { handleError } = useHandleErrorService();

    const fetchDivisions = async (
        setDivisionTableRows: React.Dispatch<React.SetStateAction<DivisionTableRows[]>>
    ) => {
        try {
            const response: AxiosResponse = await fetchAuthRequest(
                API_ENDPOINT.MANAGE.DIVISION.GET_DIVISION
            );

            if (response) {
                const { object } = response.data;
                const rows: DivisionTableRows[] = [];

                object.forEach((division: Division, index: number) => {
                    rows.push({
                        slNo: index + 1,
                        id: division.id? division.id : '',
                        divisionName: division.divisionName,
                        status: division.status
                    })
                });

                setDivisionTableRows(rows);
            }
        } catch (error) {
            handleError(error);
        }
    }

    const saveDivision = async (
        division: DivisionPost
    ) => {
        let saved = false;
        const save = async () => {
            try {
                const response: AxiosResponse = await postAuthRequest(
                    API_ENDPOINT.MANAGE.DIVISION.POST_DIVISION,
                    division
                );
                const { message } = response.data;
                toast.success(message);
                saved = true;
            } catch (error) {
                handleError(error);
            }
        }
        if (division.status == 'Deleted') {
            await Swal.fire(swalConfig({
                title: 'Are you sure you want to delete this division?',
                icon: 'question',
                confirmButtonText: 'Delete',
                showCancelButton: true,
                cancelButtonText: 'Cancel',
                position: 'center'
            })).then(async (result) => {
                if (result.isConfirmed) {
                    await save();
                }
            });
        } else {
            await save();
        }

        return saved;
    }

    const deleteDivision = async (
        division: Division
    ) => {
        let deleted = false;
        await Swal.fire(swalConfig({
            title: 'Are you sure you want to delete this division?',
            icon: 'question',
            confirmButtonText: 'Delete',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            position: 'center'
        })).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response: AxiosResponse = await postAuthRequest(
                        API_ENDPOINT.MANAGE.DIVISION.POST_DIVISION,
                        division
                    );
                    const { message } = response.data;
                    toast.success(message);
                    deleted = true;
                } catch (error) {
                    handleError(error);
                    deleted = false;
                }
            }
        });
        return deleted;
    }

    const handleInputChange = (updateState: React.Dispatch<React.SetStateAction<string>>) =>
        (event: React.ChangeEvent<HTMLSelectElement>) => {
            updateState(event.target.value);
        };

    return {
        fetchDivisions,
        handleInputChange,
        saveDivision,
        deleteDivision,
    }
}