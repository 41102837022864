import React from 'react'

interface TableActionButtonProps {
    children: React.ReactNode;
    type: string;
    onClick: () => Promise<void>;

}

const TableActionButton: React.FC<TableActionButtonProps> = ({ children, type, onClick }) => {

    let buttonClass = ''

    switch (type) {
        case 'primary':
            buttonClass += 'bg-sky-500 hover:bg-sky-600'
            break;
        case 'secondary':
            buttonClass += 'bg-slate-500 hover:bg-slate-600'
            break;
        case 'danger':
            buttonClass += 'bg-red-500 hover:bg-red-600'
            break;
        default:
            break;
    }

    return (
        <button
            className={`flex text-white py-2 px-2 rounded duration-300 ${buttonClass}`}
            onClick={onClick}>
            {children}
        </button>
    )
}

export default TableActionButton