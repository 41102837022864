import React from 'react'
import { useHandleErrorService } from '../../../hooks/useHandleErrorService'
import { AxiosResponse } from 'axios';
import { useApiService } from '../../../hooks/useApiService';
import API_ENDPOINT from '../../../constants/api-constants';
import { Trip } from '../../manage/trip-management/trip-interfaces';
import { BookingDetailsPost, ModalDetails } from './ticket-booking-interfaces';

export const useTicketBookingService = () => {

    const { fetchAuthRequest, postAuthRequest } = useApiService();
    const { handleError } = useHandleErrorService();

    const fetchTrips = async (
        tripId: string,
        setTrip: React.Dispatch<React.SetStateAction<Trip>>
    ) => {
        try {
            const response: AxiosResponse = await fetchAuthRequest(
                API_ENDPOINT.BOOKING.GET_TRIP,
                {
                    tripId: tripId
                }
            )

            setTrip(response.data.object);

        } catch (error) {
            handleError(error);
        }
    }

    const addTicket = (
        itemType: string,
        setModalDetails: React.Dispatch<React.SetStateAction<ModalDetails>>
    ) => {
        setModalDetails({
            action: 'add',
            itemType: itemType
        });
    }

    const saveBookingDetails = async (bookingDetails: BookingDetailsPost) => {
        try {
            const response: AxiosResponse = await postAuthRequest(API_ENDPOINT.BOOKING.CREATE_BOOKING, bookingDetails);
            return response.data.object;
        } catch (error) {
            handleError(error);
        }
    } 

    return {
        fetchTrips,
        addTicket,
        saveBookingDetails
    }
}
