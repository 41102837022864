import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import * as React from 'react';

interface TabWrapperProps {
    itemList: any[];
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const TabWrapper: React.FC<TabWrapperProps> = ({ itemList }) => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const tabItems = () => {
        const tabs: any[] = [];

        itemList.forEach((item: any, index: number) => {
            tabs.push(<Tab key={index} label={item.label} {...a11yProps(index)} />);
        });

        return tabs;
    }

    const tabComponents = () => {
        const componentList: any[] = [];

        itemList.forEach((item: any, index: number) => {
            componentList.push(
                <CustomTabPanel value={value} index={index} key={index}>
                    {item.content}
                </CustomTabPanel>
            )
        });

        return componentList;
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {tabItems()}
                </Tabs>
            </Box>

            {tabComponents()}
        </Box>
    );
}

export default TabWrapper;
