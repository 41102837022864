import { AxiosResponse } from "axios";
import { useHandleErrorService } from "../../../hooks/useHandleErrorService";
import { useApiService } from "../../../hooks/useApiService";
import API_ENDPOINT from "../../../constants/api-constants";
import { SelectedDaysMap, TripDay, Vessel, VesselPost, VesselTableRow } from "./vessel-interfaces";
import { ActionMeta } from "react-select";
import { SelectOption } from "../../../constants/type-constants";
import toast from "react-hot-toast";
import Swal from "sweetalert2";
import swalConfig from "../../../config/swalConfig";

export const useVesselManagementService = () => {

    const {
        fetchAuthRequest,
        postAuthRequest
    } = useApiService();

    const { handleError } = useHandleErrorService();

    const handleInputChange = (updateState: React.Dispatch<React.SetStateAction<string>>) =>
        (event: React.ChangeEvent<HTMLSelectElement>) => {
            updateState(event.target.value);
        };

    const handleNumberInputChange = (updateState: React.Dispatch<React.SetStateAction<number>>) =>
        (event: React.ChangeEvent<HTMLSelectElement>) => {
            updateState(Number(event.target.value));
        };

    const handleSelectInputChange = (updateState: React.Dispatch<React.SetStateAction<SelectOption[]>>) =>
        (newValue: SelectOption[], actionMeta: ActionMeta<SelectOption[]>) => {
            updateState(newValue);
        }

    const handleCheckboxChange = (id: string, setSelectedDays: React.Dispatch<React.SetStateAction<SelectedDaysMap>>) => {
        setSelectedDays((prevState: SelectedDaysMap) => ({
            ...prevState,
            [id]: !prevState[Number(id)],
        }));
    };

    const fetchVessels = async (
        division: string,
        setTableRows: React.Dispatch<React.SetStateAction<VesselTableRow[]>>,
        setVesselList: React.Dispatch<React.SetStateAction<Vessel[]>>
    ) => {
        try {
            const response: AxiosResponse = await fetchAuthRequest(
                API_ENDPOINT.MANAGE.VESSEL.GET_VESSELS_BY_DIVISION,
                { divisionId: division }
            );

            const { object: vessels } = response.data;
            const rows: VesselTableRow[] = [];

            vessels.forEach((vessel: Vessel, index: number) => {
                rows.push({
                    slNo: index + 1,
                    id: vessel.id,
                    vesselName: vessel.name,
                    baseGhat: vessel.baseGhat.ghatName,
                    tripDays: vessel.tripDays.map(day => ' ' + day.slice(0, 3).charAt(0).toUpperCase() + day.slice(1, 3).toLowerCase()),
                    vesselRegNo: vessel.regNo,
                    owner: vessel.owner,
                    vesselType: vessel.vesselType,
                    hasNightNavigation: vessel.hasNightNavigation,
                    status: vessel.status,
                    deckItems: vessel.vesselDeckItems
                });
            });

            setTableRows(rows);
            setVesselList(vessels);

        } catch (error) {
            handleError(error);
        }
    }

    const saveVessel = async (
        postData: VesselPost
    ) => {
        try {
            const response: AxiosResponse = await postAuthRequest(
                API_ENDPOINT.MANAGE.VESSEL.SAVE_VESSEL,
                postData
            )

            const { message } = response.data;
            toast.success(message);
            return true;
        } catch (error) {
            handleError(error);
            return false;
        }
    }

    const deleteVessel = async (
        vesselId: string
    ) => {
        let deleted = false;
        await Swal.fire(swalConfig({
            title: 'Are you sure you want to delete this vessel?',
            icon: 'question',
            confirmButtonText: 'Delete',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
            position: 'center'
        })).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    const response: AxiosResponse = await postAuthRequest(
                        API_ENDPOINT.MANAGE.VESSEL.DELETE_VESSEL,
                        { vesselId: vesselId }
                    )

                    const { message } = response.data;
                    toast.success(message);
                    deleted = true;
                } catch (error) {
                    handleError(error);
                    deleted = false;
                }
            }
        });
        return deleted;
    }

    return {
        handleInputChange,
        handleNumberInputChange,
        handleSelectInputChange,
        handleCheckboxChange,
        fetchVessels,
        saveVessel,
        deleteVessel
    }
}
