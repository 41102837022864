import React from "react";
import API_ENDPOINT from "../../../constants/api-constants";
import { SelectOption } from "../../../constants/type-constants";
import { useApiService } from "../../../hooks/useApiService";
import { useHandleErrorService } from "../../../hooks/useHandleErrorService";
import { Ghat } from "../ghat-management/ghat-interfaces";
import { Route } from "../route-management/route-interfaces";
import { ItemRate, Rate, RatePost, RateTableRow } from "./rate-management-interface";
import toast from "react-hot-toast";
import { AxiosResponse } from "axios";

export const useRateManagementService = () => {

    const { fetchAuthRequest, postAuthRequest } = useApiService();
    const { handleError } = useHandleErrorService();

    const generateGhatFromOptions = (
        routes: Route[],
        setGhatFromOptions: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setGhatFrom: React.Dispatch<React.SetStateAction<string>>
    ) => {
        const ghats: SelectOption[] = [];
        routes.forEach(route => {
            if (!ghats.find(ghat => ghat.value == route.ghatFrom.id)) {
                ghats.push({
                    value: route.ghatFrom.id,
                    label: route.ghatFrom.ghatName
                })
            }
        });
        setGhatFromOptions(ghats);
        setGhatFrom(ghats[0]?.value);
    }

    const generateGhatToOptions = (
        routes: Route[],
        ghatFrom: string,
        setGhatToOptions: React.Dispatch<React.SetStateAction<SelectOption[]>>,
        setGhatTo: React.Dispatch<React.SetStateAction<string>>
    ) => {
        const ghats: SelectOption[] = [];
        routes.forEach(route => {
            if (!ghats.find(ghat => ghat.value == route.ghatTo.id)) {
                if (route.ghatFrom.id == ghatFrom) {
                    ghats.push({
                        value: route.ghatTo.id,
                        label: route.ghatTo.ghatName
                    })
                }
            }
        });
        setGhatToOptions(ghats);
        setGhatTo(ghats[0]?.value);
    }


    const handleInputChange = (
        updateState: React.Dispatch<React.SetStateAction<string>>
    ) =>
        (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
            const value = e.target.value;
            updateState(value);
        }


    const filterRates = (
        route: Route,
        rateList: Rate[],
        setItemRateList: React.Dispatch<React.SetStateAction<ItemRate[]>>,
        vesselType: string,
        serviceType: string
    ) => {
        // const selectedRoute = routes.find(route => route.ghatFrom.id == formGhatFrom && route.ghatTo.id == formGhatTo);
        if (!route) {
            setItemRateList([]);
            return;
        }
        const rate = rateList.find((rate: Rate) => rate.route.id == route.id && rate.vesselType == vesselType && rate.serviceType == serviceType);
        if (!rate) {
            setItemRateList([]);
            return;
        }
        setItemRateList(rate.itemRates);
    }

    const fetchRatesByRoute = async (
        route: Route,
        setRateTableRows: React.Dispatch<React.SetStateAction<RateTableRow[]>>,
        setRateList: React.Dispatch<React.SetStateAction<Rate[]>>
    ) => {
        try {
            const response: AxiosResponse = await fetchAuthRequest(
                API_ENDPOINT.MANAGE.RATE.GET_RATES_BY_ROUTE,
                {
                    routeId: route.id
                }
            );
            const { object: rates } = response.data;
            const rows: RateTableRow[] = [];
            let count = 1;
            rates.forEach((rate: Rate) => {
                rate.itemRates.forEach((itemRate: ItemRate) => {
                    rows.push({
                        slNo: count,
                        id: count.toString(),
                        vesselType: rate.vesselType,
                        serviceType: rate.serviceType,
                        itemType: itemRate.item.itemType || '',
                        itemUnitType: itemRate.item.unitType || '',
                        itemLabel: `${itemRate.item.itemType} (${itemRate.item.unitType})`,
                        rate: itemRate.rate
                    });
                    count++;
                })
            });
            setRateTableRows(rows);
            setRateList(rates);
        } catch (error) {
            handleError(error);
        }
    }

    const handleRateInputChange = (
        itemRateList: ItemRate[],
        setItemRateList: React.Dispatch<React.SetStateAction<ItemRate[]>>
    ) => (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const [_, itemId] = e.target.name.split('_');
        const rateValue = e.target.value;

        // Find the existing item or create a new one if it doesn't exist
        const existingItemIndex = itemRateList.findIndex(rateItem => rateItem.item.id == itemId);
        const newItem: ItemRate = {
            // id: Math.max(...deckItems.map(item => item.id)) + 1,
            item: { id: itemId },
            rate: rateValue
        };

        if (existingItemIndex >= 0) {
            // Update the existing item
            const updatedRateList = [...itemRateList];
            updatedRateList[existingItemIndex] = newItem;
            setItemRateList(updatedRateList);
        } else {
            // Add a new item
            setItemRateList([...itemRateList, newItem]);
        }
    }


    const saveRates = async (
        postData: RatePost
    ) => {
        try {
            const response: AxiosResponse = await postAuthRequest(
                API_ENDPOINT.MANAGE.RATE.SAVE_ROUTE,
                postData
            );
            const { message } = response.data;
            toast.success(message);
        } catch (error) {
            handleError(error)
        }
    }

    return {
        generateGhatFromOptions,
        generateGhatToOptions,
        handleInputChange,
        handleRateInputChange,
        fetchRatesByRoute,
        filterRates,
        saveRates
    }
}